import { gql } from '@apollo/client'

import { FRAGMENT_SESSION_PASS } from 'graphql/fragments/sessionFragment'
import { AnswerQuestionInput, Session } from 'graphql/types'

export interface IAnswerQuestionResponse {
  result: Session
}

export interface IAnswerQuestionVars {
  input: AnswerQuestionInput
}

export const MUTATION_ANSWER_QUESTION = gql`
  mutation answerQuestion($input: AnswerQuestionInput!) {
    result: answerQuestion(input: $input) {
      ...FragmentSessionPass
    }
  }

  ${FRAGMENT_SESSION_PASS}
`
