import { QueryHookOptions, useQuery } from '@apollo/client'

import {
  IQueryGetDetailedReportVars,
  IGetDetailedReportResponse,
  QUERY_GET_DETAILED_REPORT,
} from '../gql/getDetailedReport'

export const useDetailedReportQuery = (
  options?: QueryHookOptions<
    IGetDetailedReportResponse,
    IQueryGetDetailedReportVars
  >
) => {
  return useQuery<IGetDetailedReportResponse, IQueryGetDetailedReportVars>(
    QUERY_GET_DETAILED_REPORT,
    options
  )
}

export const useDetailedReportInfo = (externalId: string) => {
  const { data, loading, error } = useDetailedReportQuery({
    variables: { externalId },
  })

  return {
    id: data?.result.id ?? '',
    score: data?.result.score ?? 0,
    maxScore: data?.result.maxScore ?? 0,
    publicName: data?.result.publicName ?? '',
    publicNameRu: data?.result.publicNameRu ?? '',
    netTime: data?.result.netTime ?? 0,
    Tasks: data?.result.Tasks ?? [],
    isLoading: loading,
    duration: data?.result.duration ?? 0,
    error: error,
  }
}
