import { MutationHookOptions, useMutation } from '@apollo/client'

import {
  IRunTestCasesResponse,
  IRunTestCasesVars,
  MUTATION_RUN_TEST_CASES,
} from '../gql/runTestCases'

export const useRunTestCasesMutation = (
  options?: MutationHookOptions<IRunTestCasesResponse, IRunTestCasesVars>
) => {
  return useMutation<IRunTestCasesResponse, IRunTestCasesVars>(
    MUTATION_RUN_TEST_CASES,
    options
  )
}
