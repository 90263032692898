import { gql } from '@apollo/client'

import { FRAGMENT_SESSION_PASS } from 'graphql/fragments/sessionFragment'
import { Session } from 'graphql/types'

export interface IGetSessionResponse {
  result: Session
}

export interface IQueryGetSessionReportVars {
  sessionGuid: string
}

export const QUERY_GET_SESSION = gql`
  query getSession($sessionGuid: String!) {
    result: getSession(sessionGuid: $sessionGuid) {
      ...FragmentSessionPass
    }
  }

  ${FRAGMENT_SESSION_PASS}
`
