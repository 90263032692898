import { QueryHookOptions, useQuery } from '@apollo/client'

import {
  IQueryGetSessionReportVars,
  IGetSessionResponse,
  QUERY_GET_SESSION,
} from '../gql/getSession'

export const useSessionQuery = (
  options?: QueryHookOptions<IGetSessionResponse, IQueryGetSessionReportVars>
) => {
  return useQuery<IGetSessionResponse, IQueryGetSessionReportVars>(
    QUERY_GET_SESSION,
    options
  )
}

export const useSessionInfo = (sessionGuid: string) => {
  const { data, loading, error, startPolling, stopPolling } = useSessionQuery({
    variables: { sessionGuid },
  })

  return {
    id: data?.result.id || '',
    publicName: data?.result.publicName || '',
    publicNameRu: data?.result.publicNameRu || '',
    status: data?.result.status || '',
    Tasks: data?.result?.Tasks || [],
    serverTime: data?.result.System?.serverTime || 0,
    error: error,
    isLoading: loading,
    startPolling: startPolling,
    stopPolling: stopPolling,
  }
}
