import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Button,
  EnumButtonVariants,
  Modal,
  EnumTypographyVariants,
  Typography,
  useDebounce,
  useToggle,
  EnumToastVariants,
} from '@insquad/tools'

import Header from 'common/components/Header/Header'
import { openToast } from 'common/components/Toast/Toast'
import { useChangeEnvironmentMutation } from 'graphql/mutations/environment/hooks/useChangeEnvironmentMutation'
import { useSyncEventsMutation } from 'graphql/mutations/events/hooks/useSyncEventsMutation'
import { useRunTestCasesMutation } from 'graphql/mutations/test/hooks/useRunTestCasesMutation'
import { useGetEnvironments } from 'graphql/queries/session/hooks/useEnvironmentsQuery'
import { EventType, Task, TaskStatus } from 'graphql/types'

import { CodingContent } from './CodingContent/CodingContent'
import { CodingFooter } from './CodingFooter/CodingFooter'

import s from './CodingPage.module.scss'

export const CodingPage: React.FC<{
  headerTitle: string
  Task: Task
  currentTask: number
  amountTasks: number
  diffTime: number

  handleFinishSaveEvents: () => void
  handleChangeActiveTask: () => void
  handleGetNextTask: () => void
}> = ({
  headerTitle,
  Task,
  currentTask,
  amountTasks,
  diffTime,
  handleFinishSaveEvents,
  handleChangeActiveTask,
  handleGetNextTask,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const [fontSize, setFontSize] = useState<number>(14)
  const [code, setCode] = useState<string>(
    Task.Coding?.currentCode || Task.Coding?.initialCode || ''
  )
  const debouncedCode = useDebounce<string>(code, 1000)
  const [counter, setCounter] = useState<number>(600)

  const [environment, setEnvironment] = useState<string>(
    Task.Coding?.environment || ''
  )
  const modalToggler = useToggle()
  const isTaskVisible = useToggle(true)
  const isLoadingRunTest = useToggle(false)

  const { environments, isLoading: isLoadingEnv } = useGetEnvironments()

  const [changeEnv] = useChangeEnvironmentMutation()

  const [runTestCases, { data, loading, reset }] = useRunTestCasesMutation({
    onCompleted() {
      modalToggler.unset()
    },
    onError() {
      openToast({
        text: 'Failed run test. Please, reload page',
        variant: EnumToastVariants.ERROR,
      })
    },
  })

  const onChangeEnvironment = (env: string) => {
    changeEnv({
      variables: {
        input: {
          codingLogGuid: Task.Coding?.id || '',
          environment: env,
        },
      },
    })
    setEnvironment(env)
  }

  const [saveEvents] = useSyncEventsMutation()

  const increment = () => setFontSize((x) => x + 1)
  const decrement = () => setFontSize((x) => x - 1)

  const startedAt = useMemo(
    () => Date.parse(Task?.startedAt || '') / 1000,
    [Task.startedAt]
  )

  const time = useMemo(() => {
    return (
      (Task.startedAt ? startedAt + diffTime - Date.now() / 1000 : 0) +
      (Task?.timeLimit || 0)
    )
  }, [diffTime, Task, currentTask])

  const handleRunTest = (isCompleted: boolean) => {
    const date = new Date()
    const currentDate = date.toISOString()

    runTestCases({
      variables: {
        input: {
          taskGuid: Task.id || '',
          code: code,
          trackedAt: currentDate,
          isCompleted: isCompleted,
        },
      },
      onCompleted() {
        if (isCompleted) {
          reset()
        }
      },
    })
    isTaskVisible.unset()
  }

  const handleEditorChange = (value?: string) => {
    if (value) {
      setCode(value)
    }
  }

  const submitCode = () => {
    handleFinishSaveEvents()
    handleGetNextTask()
    handleRunTest(true)
    handleChangeActiveTask()
    isTaskVisible.set()
    modalToggler.unset()
  }

  useEffect(() => {
    setCounter(Number(time.toFixed()))
  }, [time, currentTask])

  useEffect(() => {
    const timer = setInterval(() => setCounter(counter - 1), 1000)
    if (counter <= 0) {
      submitCode()
      clearInterval(timer)
    }
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter])

  useEffect(() => {
    handleEditorChange(
      Task.Coding?.currentCode || Task.Coding?.initialCode || ''
    )
  }, [Task])

  useEffect(() => {
    const date = new Date()
    const currentDate = date.toISOString()

    if (Task.status !== TaskStatus.Finished) {
      saveEvents({
        variables: {
          input: {
            taskGuid: Task.id || '',
            events: [
              {
                trackedAt: currentDate,
                type: EventType.Snapshot,
                code: debouncedCode,
              },
            ],
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedCode])

  useEffect(() => {
    setEnvironment(Task.Coding?.environment || '')
  }, [Task, currentTask])

  useEffect(() => {
    isLoadingRunTest.setValue(loading)
  }, [loading])

  useEffect(() => {
    isLoadingRunTest.setValue(false)
  }, [currentTask])

  return (
    <div>
      <Header title={headerTitle} />
      <CodingContent
        currentTask={currentTask}
        amountTasks={amountTasks}
        fontSize={fontSize}
        timer={counter}
        Task={Task}
        code={code}
        CodingTest={data?.result}
        environment={environment}
        handleEditorChange={handleEditorChange}
        isLoadingTestRunner={isLoadingRunTest.value}
        isTaskVisible={isTaskVisible}
        initialWidth={480}
      />
      <CodingFooter
        size={fontSize}
        environment={environment}
        environments={environments}
        isMultiEnvironments={Task.isMultiEnvironment}
        increment={increment}
        decrement={decrement}
        handleOpenModal={modalToggler.set}
        handleRunTest={handleRunTest}
        onChangeEnvironment={onChangeEnvironment}
        isLoadingTestRunner={isLoadingRunTest.value}
        isLoadingEnv={isLoadingEnv}
      />
      <Modal
        isOpen={modalToggler.value}
        onClose={modalToggler.unset}
        title={
          isRuLocale
            ? 'Вы уверены, что хотите отправить эту задачу?'
            : 'Are you sure you want to submit this task?'
        }
        enableCloseIcon
      >
        <Typography
          variant={EnumTypographyVariants.P2}
          text={
            isRuLocale
              ? 'Вы не сможете вернуться к этому позже.'
              : `You won't be able to come back to it later.`
          }
        />
        <div className={s.CodingPage__modalButtonBox}>
          <Button
            onClick={modalToggler.unset}
            className={s.CodingPage__modalButton}
          >
            {isRuLocale ? 'Вернуться к задаче' : 'Back to task'}
          </Button>
          <Button
            variant={EnumButtonVariants.SECONDARY}
            className={s.CodingPage__modalButton}
            onClick={submitCode}
            isLoading={isLoadingRunTest.value}
          >
            {isRuLocale ? 'Отправить' : 'Submit'}
          </Button>
        </div>
      </Modal>
    </div>
  )
}
