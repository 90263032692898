export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type AnswerQuestionInput = {
  questionGuid: Scalars['String'];
  variantGuids?: InputMaybe<Array<Scalars['String']>>;
};

export type ChangeEnvironmentInput = {
  codingLogGuid: Scalars['String'];
  environment: Scalars['String'];
};

/** coding */
export type Coding = {
  __typename?: 'Coding';
  CodingTest?: Maybe<CodingTest>;
  complexity?: Maybe<Complexity>;
  currentCode?: Maybe<Scalars['String']>;
  environment?: Maybe<Scalars['String']>;
  finalCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  initialCode?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  publicName: Scalars['String'];
  publicNameRu?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  textRu?: Maybe<Scalars['String']>;
};

/** coding-test */
export type CodingTest = {
  __typename?: 'CodingTest';
  CodingTestCases?: Maybe<Array<CodingTestCase>>;
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  maxScore: Scalars['Float'];
  score: Scalars['Float'];
};

/** coding-test-case */
export type CodingTestCase = {
  __typename?: 'CodingTestCase';
  TestCaseArguments?: Maybe<Array<TestCaseArgument>>;
  expectedValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isVisible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['String']>;
  passed: Scalars['Boolean'];
  returnedValue?: Maybe<Scalars['String']>;
};

export enum Complexity {
  Easy = 'EASY',
  High = 'HIGH',
  Medium = 'MEDIUM'
}

/** environment */
export type Environment = {
  __typename?: 'Environment';
  name?: Maybe<Scalars['String']>;
  publicName?: Maybe<Scalars['String']>;
};

/** event */
export type Event = {
  __typename?: 'Event';
  CodingTest?: Maybe<CodingTest>;
  code?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  trackedAt?: Maybe<Scalars['DateTime']>;
  type: EventType;
};

export type EventInput = {
  code?: InputMaybe<Scalars['String']>;
  trackedAt: Scalars['DateTime'];
  type: EventType;
};

export enum EventType {
  Blur = 'BLUR',
  Focus = 'FOCUS',
  Snapshot = 'SNAPSHOT',
  TestExecution = 'TEST_EXECUTION'
}

export type Mutation = {
  __typename?: 'Mutation';
  answerQuestion: Session;
  changeEnvironment: Session;
  runTestCases: CodingTest;
  syncEvents: Scalars['Boolean'];
};


export type MutationAnswerQuestionArgs = {
  input: AnswerQuestionInput;
};


export type MutationChangeEnvironmentArgs = {
  input: ChangeEnvironmentInput;
};


export type MutationRunTestCasesArgs = {
  input: RunTestCasesInput;
};


export type MutationSyncEventsArgs = {
  input: SyncEventsInput;
};

export type Query = {
  __typename?: 'Query';
  getDetailedReport: Session;
  getEnvironments: Array<Environment>;
  getSession: Session;
};


export type QueryGetDetailedReportArgs = {
  externalId: Scalars['String'];
};


export type QueryGetSessionArgs = {
  sessionGuid: Scalars['String'];
};

/** question */
export type Question = {
  __typename?: 'Question';
  Variants?: Maybe<Array<Variant>>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isPassed?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Float']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  textRu?: Maybe<Scalars['String']>;
  timeLimit?: Maybe<Scalars['Float']>;
  title: Scalars['String'];
  titleRu?: Maybe<Scalars['String']>;
  type: QuestionType;
};

export enum QuestionType {
  Multiple = 'MULTIPLE',
  Single = 'SINGLE'
}

/** quiz */
export type Quiz = {
  __typename?: 'Quiz';
  CurrentQuestion?: Maybe<Question>;
  Questions?: Maybe<Array<Question>>;
  currentQuestionPosition?: Maybe<Scalars['Float']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  publicName: Scalars['String'];
  publicNameRu?: Maybe<Scalars['String']>;
  questionsCount?: Maybe<Scalars['Float']>;
};

export type RunTestCasesInput = {
  code: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  taskGuid: Scalars['String'];
  trackedAt: Scalars['DateTime'];
};

/** session */
export type Session = {
  __typename?: 'Session';
  System?: Maybe<System>;
  Tasks?: Maybe<Array<Task>>;
  duration?: Maybe<Scalars['Float']>;
  externalId: Scalars['String'];
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  maxScore?: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  netTime?: Maybe<Scalars['Float']>;
  publicName: Scalars['String'];
  publicNameRu?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Float']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskStatus;
};

export type SyncEventsInput = {
  events?: InputMaybe<Array<EventInput>>;
  taskGuid: Scalars['String'];
};

/** system */
export type System = {
  __typename?: 'System';
  serverTime: Scalars['Float'];
};

/** tag */
export type Tag = {
  __typename?: 'Tag';
  name: Scalars['ID'];
  publicName: Scalars['String'];
  publicNameRu?: Maybe<Scalars['String']>;
};

/** task */
export type Task = {
  __typename?: 'Task';
  Coding?: Maybe<Coding>;
  Events?: Maybe<Array<Event>>;
  Quiz?: Maybe<Quiz>;
  Tags?: Maybe<Array<Tag>>;
  duration?: Maybe<Scalars['Float']>;
  finishedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isMultiEnvironment: Scalars['Boolean'];
  maxScore?: Maybe<Scalars['Float']>;
  netTime?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  status: TaskStatus;
  timeLimit?: Maybe<Scalars['Float']>;
  type: TaskType;
};

export enum TaskStatus {
  Finished = 'FINISHED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  OnPause = 'ON_PAUSE'
}

export enum TaskType {
  Coding = 'CODING',
  Quiz = 'QUIZ'
}

/** test-case-argument */
export type TestCaseArgument = {
  __typename?: 'TestCaseArgument';
  codingLogGuid: Scalars['String'];
  name: Scalars['String'];
  testCaseLogGuid: Scalars['String'];
  value: Scalars['String'];
};

/** variant */
export type Variant = {
  __typename?: 'Variant';
  id: Scalars['ID'];
  isCorrect?: Maybe<Scalars['Boolean']>;
  isSelected?: Maybe<Scalars['Boolean']>;
  text: Scalars['String'];
  textRu?: Maybe<Scalars['String']>;
};
