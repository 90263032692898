import React from 'react'
import ReactMarkdown from 'react-markdown'

import clsx from 'clsx'

import { Typography, EnumTypographyVariants } from '@insquad/tools'

import { ReactComponent as ChevronIcon } from 'assets/icons/chevronIcon.svg'

import s from './TaskDescription.module.scss'

export interface ITaskDescriptionProps {
  title: string
  text: string
  isOpen: boolean

  handleClick: () => void
}

const TaskDescription: React.FC<ITaskDescriptionProps> = ({
  title,
  text,
  isOpen,
  handleClick,
}) => {
  return (
    <div className={s.TaskDescription}>
      <div className={s.TaskDescription__header} onClick={handleClick}>
        <Typography variant={EnumTypographyVariants.H3} text={title} as="div" />
        <div className={s.TaskDescription__iconWrapper}>
          <ChevronIcon
            className={clsx(s.TaskDescription__icon, {
              [s.TaskDescription__icon_open]: isOpen,
            })}
          />
        </div>
      </div>
      {isOpen && (
        <ReactMarkdown className={s.TaskDescription__text}>
          {text}
        </ReactMarkdown>
      )}
    </div>
  )
}

export default TaskDescription
