import React from 'react'

import { useToggle } from '@insquad/tools'

import s from './Tooltip.module.scss'

interface ITooltipProps {
  text: React.ReactNode
  children: JSX.Element
  position: number
}

const Tooltip: React.FC<ITooltipProps> = ({
  children,
  text,
  position,
  ...rest
}) => {
  const isShow = useToggle()

  return (
    <div>
      <div
        className={s.Tooltip}
        style={
          isShow.value
            ? { visibility: 'visible', left: `${position - 73}px` }
            : {}
        }
      >
        {text}
        <span className={s.Tooltip__arrow} />
      </div>
      <div {...rest} onMouseEnter={isShow.set} onMouseLeave={isShow.unset}>
        {children}
      </div>
    </div>
  )
}

export default Tooltip
