import React from 'react'
import { useSearchParams } from 'react-router-dom'
import Select from 'react-select'

import { Question } from 'graphql/types'

import './QuestionListSelect.scss'

enum FilterOptionValues {
  ALL,
  PASSED,
  NOT_PASSED,
}

export type SelectOption = {
  value: FilterOptionValues
  label: string
  filter: (q: Question) => boolean
}

export const INITIAL_OPTION: SelectOption = {
  value: FilterOptionValues.ALL,
  label: 'Show all answers',
  filter: () => true,
}

export const INITIAL_OPTION_RU: SelectOption = {
  value: FilterOptionValues.ALL,
  label: 'Показать все ответы',
  filter: () => true,
}

const FILTER_OPTIONS: SelectOption[] = [
  INITIAL_OPTION,
  {
    value: FilterOptionValues.PASSED,
    label: 'Show only correct answers',
    filter: (q) => !!q.isPassed,
  },
  {
    value: FilterOptionValues.NOT_PASSED,
    label: 'Show only incorrect answers',
    filter: (q) => !q.isPassed,
  },
]

const FILTER_OPTIONS_RU: SelectOption[] = [
  INITIAL_OPTION_RU,
  {
    value: FilterOptionValues.PASSED,
    label: 'Показать правильные ответы',
    filter: (q) => !!q.isPassed,
  },
  {
    value: FilterOptionValues.NOT_PASSED,
    label: 'Показать неправильные ответы',
    filter: (q) => !q.isPassed,
  },
]

interface IQuestionListSelectProps {
  value: SelectOption | null
  className?: string
  onChange: (option: SelectOption | null) => void
}

export const QuestionListSelect: React.FC<IQuestionListSelectProps> = ({
  value,
  onChange,
  className,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'

  return (
    <Select
      value={value}
      onChange={onChange}
      options={!isRuLocale ? FILTER_OPTIONS : FILTER_OPTIONS_RU}
      className={className}
      classNamePrefix={'select'}
      isSearchable={false}
    />
  )
}
