import React from 'react'

import {
  EnumTypographyVariants,
  Typography,
  EnumBreakpoints,
  useMediaQuery,
} from '@insquad/tools'

import { ReactComponent as LogoIcon } from 'assets/icons/logoIcon.svg'
import { Avatar } from 'common/components/Avatar/Avatar'

import s from './Header.module.scss'

export interface IHeaderProps {
  avatar?: string
  name?: string
  title?: string
}

const Header: React.FC<IHeaderProps> = ({ avatar, name, title }) => {
  const isMobile = useMediaQuery(EnumBreakpoints.BREAKPOINT_SM, 'max')
  // const isTablet = useMediaQuery(EnumBreakpoints.BREAKPOINT_LG, 'max')

  return (
    <header className={s.Header}>
      <div className={s.Header__container}>
        {/* {isTablet ? (
          <Typography
            variant={
              isMobile ? EnumTypographyVariants.P2 : EnumTypographyVariants.P1
            }
            as="div"
            className={s.Header__button}
          >
            <ChevronIcon className={s.Header__buttonIcon} />
            Back to profile
          </Typography>
        ) : (
          <Button
            variant={EnumButtonVariants.SECONDARY}
            iconLeft={<ChevronIcon className={s.Header__buttonIcon} />}
          >
            Back to profile
          </Button>
        )} */}
        {title ? (
          <Typography
            variant={EnumTypographyVariants.H4}
            as="div"
            text={title}
          />
        ) : (
          <Typography
            variant={EnumTypographyVariants.P2}
            as="div"
            className={s.Header__candidate}
          >
            {!!avatar && <Avatar avatarUrl={avatar} />}
            {!!name ? name[0].toUpperCase() + name.slice(1) : ''}
          </Typography>
        )}
      </div>
      {!isMobile && (
        <Typography
          variant={EnumTypographyVariants.H4}
          as="div"
          className={s.Header__container}
        >
          <LogoIcon />
          <div className={s.Header__ellipse}></div>
          Skills Assessment
        </Typography>
      )}
    </header>
  )
}

export default Header
