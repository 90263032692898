import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as ChevronIcon } from './chevronIcon.svg'

import s from './TextSize.module.scss'

export const TextSize: React.FC<{
  size: number
  increment: () => void
  decrement: () => void
}> = ({ size, increment, decrement }) => {
  return (
    <div className={s.TextSize}>
      <Typography variant={EnumTypographyVariants.P3} text={`${size} pt`} />
      <div className={s.TextSize__button}>
        <ChevronIcon className={s.TextSize__chevron} onClick={increment} />
        <ChevronIcon
          className={clsx(s.TextSize__chevron, s.TextSize__chevron_down)}
          onClick={decrement}
        />
      </div>
    </div>
  )
}
