import { gql } from '@apollo/client'

export const FRAGMENT_QUIZ_FULL = gql`
  fragment FragmentQuizFull on Quiz {
    id
    name
    publicName
    publicNameRu
    Questions {
      id
      title
      titleRu
      text
      textRu
      order
      type
      timeLimit
      isPassed
      startedAt
      finishedAt
      Variants {
        id
        text
        textRu
        isCorrect
        isSelected
      }
    }
  }
`

export const FRAGMENT_QUIZ_PASS = gql`
  fragment FragmentQuizPass on Quiz {
    id
    name
    publicName
    publicNameRu
    questionsCount
    currentQuestionPosition
    Questions {
      id
    }
    CurrentQuestion {
      id
      title
      titleRu
      text
      textRu
      order
      type
      timeLimit
      isPassed
      startedAt
      finishedAt
      Variants {
        id
        text
        textRu
      }
    }
  }
`
