import { gql } from '@apollo/client'

import { FRAGMENT_SESSION_PASS } from 'graphql/fragments/sessionFragment'
import { ChangeEnvironmentInput, Session } from 'graphql/types'

export interface IChangeEnvironmentResponse {
  result: Session
}

export interface IChangeEnvironmentVars {
  input: ChangeEnvironmentInput
}

export const MUTATION_ANSWER_QUESTION = gql`
  mutation changeEnvironment($input: ChangeEnvironmentInput!) {
    result: changeEnvironment(input: $input) {
      ...FragmentSessionPass
    }
  }

  ${FRAGMENT_SESSION_PASS}
`
