import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import rehypeHighlight from 'rehype-highlight'

import {
  Button,
  EnumTypographyVariants,
  Typography,
  useIsMobile,
} from '@insquad/tools'

import { ReactComponent as IndicatorIcon } from 'assets/icons/indicatorIcon.svg'
import { TimeCounter } from 'common/components/TimeCounter/TimeCounter'
import { Variant } from 'graphql/types'

import s from './Question.module.scss'

interface IQuestionProps {
  title: string
  text?: string
  timer: number
  isMultipleAnswer: boolean
  currentQuestion: number
  amountQuestion: number
  variants: Variant[]
  isLoading: boolean
  handleOpenModal: () => void
  handleSave: (answers: string[]) => void
  handleFinishSaveEvents: () => void
  handlePostMessage: () => void
}

export const Question: React.FC<IQuestionProps> = ({
  title,
  text,
  timer,
  isMultipleAnswer,
  currentQuestion,
  amountQuestion,
  variants,
  isLoading,
  handleOpenModal,
  handleSave,
  handleFinishSaveEvents,
  handlePostMessage,
}) => {
  const [selected, setSelected] = useState<string[]>([])
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const isLastQuestion = currentQuestion === amountQuestion

  const isMobile = useIsMobile()

  const handleClickNext = () => {
    handleSave(selected)
    setSelected([])
  }

  const handleFinish = () => {
    handleFinishSaveEvents()
    handleClickNext()
    handlePostMessage()
  }

  const handleSelectedAnswer = (id: string) => {
    if (isMultipleAnswer) {
      setSelected((selected) => [...selected, id])
    } else {
      setSelected([id])
    }
    if (selected.includes(id)) {
      setSelected((selected) => selected.filter((item) => item !== id))
    }
  }

  const renderButton = () => {
    if (isLastQuestion) {
      return (
        <Button
          className={s.Question__button}
          onClick={handleFinish}
          disabled={!selected.length}
          isLoading={isLoading}
        >
          {isRuLocale ? 'Завершить Квиз' : 'Finish Quiz'}
        </Button>
      )
    }
    return (
      <Button
        className={s.Question__button}
        onClick={handleClickNext}
        disabled={!selected.length}
        isLoading={isLoading}
      >
        {isRuLocale ? 'Далее' : 'Next'}
      </Button>
    )
  }

  return (
    <div className={s.Question}>
      {!isMobile && currentQuestion && (
        <TimeCounter
          timer={timer}
          title={`${
            isRuLocale ? 'Вопрос' : 'Question'
          } ${currentQuestion}/${amountQuestion}`}
        />
      )}

      <div>
        <Typography variant={EnumTypographyVariants.H2} text={title} />
        {isMultipleAnswer && (
          <Typography
            variant={EnumTypographyVariants.P2}
            text={
              isRuLocale
                ? 'Выберите один или несколько ответов'
                : 'Choose one or more answers'
            }
            className={s.Question__text_multiple}
          />
        )}
      </div>

      {text && (
        <ReactMarkdown
          className={s.Question__description}
          rehypePlugins={[[rehypeHighlight, { ignoreMissing: true }]]}
        >
          {text}
        </ReactMarkdown>
      )}
      <div className={s.Question__answerList}>
        {variants.map((variant, i) => (
          <div
            key={i}
            className={clsx(s.Question__answer, {
              [s.Question__answer_selected]: selected.includes(variant.id),
            })}
            onClick={() => handleSelectedAnswer(variant.id)}
          >
            {selected.includes(variant.id) && <IndicatorIcon />}
            <ReactMarkdown className={s.Question__answerText}>
              {!isRuLocale
                ? variant.text
                : variant.textRu ?? variant.text ?? ''}
            </ReactMarkdown>
          </div>
        ))}
      </div>
      <div className={s.Question__buttonContainer}>
        {isMobile && renderButton()}
        <Typography
          variant={EnumTypographyVariants.P2}
          text={
            isRuLocale
              ? `Пропустить этот вопрос${
                  isLastQuestion ? ' и закончить квиз' : ''
                }`
              : `Skip this question${isLastQuestion ? ' and finish quiz' : ''}`
          }
          className={s.Question__skipButton}
          onClick={handleOpenModal}
        />
        {!isMobile && renderButton()}
      </div>
    </div>
  )
}
