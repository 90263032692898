import React, { useCallback, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import {
  Spinner,
  EnumTypographyVariants,
  EnumToastVariants,
  Typography,
} from '@insquad/tools'

import Header from 'common/components/Header/Header'
import TitleTask from 'common/components/TitleTask/TitleTask'
import { openToast } from 'common/components/Toast/Toast'
import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'
import { useDetailedReportInfo } from 'graphql/queries/report/hooks/useDetailedReportQuery'
import { EventType, TaskType } from 'graphql/types'

import { CodingViewPage } from './CodingViewPage/CodingViewPage'
import { QuestionViewPage } from './QuizViewPage/QuestionViewPage'

import s from './ReportPage.module.scss'

export function ReportPage() {
  const [activeTask, setActiveTask] = useState(0)

  const { externalId = '' } = useParams<string>()
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'

  const {
    score,
    maxScore,
    publicName,
    publicNameRu,
    netTime,
    duration,
    Tasks,
    isLoading,
    error,
  } = useDetailedReportInfo(externalId)

  const events = useMemo(() => {
    const filteredEvents = Tasks[activeTask]?.Events?.filter(
      (event) => event.type !== EventType.Focus && event.type !== EventType.Blur
    )

    const typeArray = filteredEvents?.map((item) => item.type)

    const indexTestExecution = typeArray?.lastIndexOf(EventType.TestExecution)

    return filteredEvents?.slice(
      0,
      !!indexTestExecution ? indexTestExecution + 1 : 0
    )
  }, [Tasks, activeTask])

  const renderCurrentView = useCallback(() => {
    if (Tasks[activeTask]?.type === TaskType.Quiz) {
      return (
        <QuestionViewPage
          publicName={isRuLocale ? publicNameRu || publicName : publicName}
          Task={Tasks[activeTask]}
        />
      )
    }

    if (Tasks[activeTask]?.type === TaskType.Coding) {
      return (
        <CodingViewPage
          Task={Tasks[activeTask]}
          activeTask={activeTask}
          events={events}
        />
      )
    }

    return null
  }, [Tasks, activeTask, events, publicName])

  if (error) {
    openToast({
      text: `${error.message}`,
      variant: EnumToastVariants.ERROR,
    })

    return <NotFoundLayout />
  }

  return (
    <>
      <Header
        avatar={searchParams.get('avatarUrl') || ''}
        name={searchParams.get('name') || ''}
      />
      {isLoading && (
        <div className={s.ReportPage__spinner}>
          <Spinner size="xl" />{' '}
        </div>
      )}

      {!isLoading && (
        <>
          <div
            className={clsx(s.ReportPage__title, {
              [s.ReportPage__title_border]: Tasks.length > 1,
            })}
          >
            <TitleTask
              title={isRuLocale ? publicNameRu || publicName : publicName}
              isSmallTextCircle
              isBorderHidden
              netTime={
                Tasks[activeTask]?.type === TaskType.Coding ? netTime : 0
              }
              tags={
                Tasks[activeTask]?.type !== TaskType.Coding
                  ? Tasks[activeTask]?.Tags
                  : []
              }
              duration={duration}
              score={score}
              maxScore={maxScore}
              isAnimatedCircle
            />
            <div
              className={clsx(s.ReportPage__tasks, {
                [s.ReportPage__tasks_border]: Tasks.length === 1,
              })}
            >
              {Tasks.length > 1 &&
                Tasks.map((_, i) => (
                  <Typography
                    onClick={() => setActiveTask(i)}
                    key={i}
                    text={`Task #${i + 1}`}
                    variant={EnumTypographyVariants.P1}
                    className={clsx(s.ReportPage__task, {
                      [s.ReportPage__task_active]: i === activeTask,
                    })}
                  />
                ))}
            </div>
          </div>

          {renderCurrentView()}
        </>
      )}
    </>
  )
}
