import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import {
  Typography,
  EnumTypographyVariants,
  Spinner,
  IToggler,
} from '@insquad/tools'

import TestCase from 'common/components/TestCases/TestCase/TestCase'
import { CodingTest, Task } from 'graphql/types'
import { getIsPassedTest } from 'utils/getIsPassedTest'
import { getOutputString } from 'utils/getOutputString'

import s from './CodingContentAside.module.scss'

export const CodingContentAside: React.FC<{
  Task: Task
  CodingTest?: CodingTest
  isLoadingTestRunner: boolean
  asideWidth: string
  isTaskVisible: IToggler
}> = ({ Task, CodingTest, isLoadingTestRunner, isTaskVisible, asideWidth }) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'

  const renderCurrentTestCase = () => {
    return (
      <>
        {CodingTest?.CodingTestCases?.length ? (
          <div className={s.CodingContentAside__testCases}>
            {CodingTest.CodingTestCases?.map((test, i) => {
              return (
                <TestCase
                  key={test.id}
                  name={`#${i + 1}`}
                  isCorrect={test.passed}
                  expectedValue={test.expectedValue}
                  returnedValue={test.returnedValue}
                  output={getOutputString(test.output || '')}
                  isHiddenDescription={!test.isVisible}
                />
              )
            })}
          </div>
        ) : (
          <Typography
            variant={EnumTypographyVariants.P2}
            text={'The code has not been run'}
          />
        )}
      </>
    )
  }

  return (
    <aside className={s.CodingContentAside} style={{ width: asideWidth }}>
      <div
        className={s.CodingContentAside__tabBar}
        style={{ width: asideWidth }}
      >
        <Typography
          variant={EnumTypographyVariants.P1}
          text={'Task'}
          className={clsx(s.CodingContentAside__tab, {
            [s.CodingContentAside__tab_active]: isTaskVisible.value,
          })}
          as="div"
          onClick={isTaskVisible.set}
        />
        <Typography
          variant={EnumTypographyVariants.P1}
          text={'Test Cases'}
          className={clsx(s.CodingContentAside__tab, {
            [s.CodingContentAside__tab_active]: !isTaskVisible.value,
          })}
          as="div"
          onClick={isTaskVisible.unset}
        />
      </div>
      {isTaskVisible.value && (
        <>
          <ReactMarkdown className={s.CodingContentAside__text}>
            {!isRuLocale
              ? Task.Coding?.text || ''
              : Task.Coding?.textRu || Task.Coding?.text || ''}
          </ReactMarkdown>
        </>
      )}
      {!isTaskVisible.value && (
        <div className={s.CodingContentAside__console}>
          <Typography
            variant={EnumTypographyVariants.P1}
            as="div"
            className={s.CodingContentAside__test}
          >
            {'Test cases'}
            {!!CodingTest?.CodingTestCases?.length && (
              <Typography
                variant={EnumTypographyVariants.P3}
                text={`${CodingTest.score}/${CodingTest.maxScore}`}
                className={clsx(s.CodingContentAside__testTag, {
                  [s.CodingContentAside__testTag_green]: getIsPassedTest(
                    CodingTest.score,
                    CodingTest.maxScore
                  ),
                  [s.CodingContentAside__testTag_red]: !getIsPassedTest(
                    CodingTest.score,
                    CodingTest.maxScore
                  ),
                })}
              />
            )}
          </Typography>
          {isLoadingTestRunner && (
            <div className={s.CodingContentAside__spinner}>
              <Spinner size="md" />
            </div>
          )}
          {!isLoadingTestRunner && renderCurrentTestCase()}
        </div>
      )}
    </aside>
  )
}
