import { MutationHookOptions, useMutation } from '@apollo/client'

import {
  ISyncEventsResponse,
  ISyncEventsVars,
  MUTATION_SYNC_EVENTS,
} from '../gql/syncEvents'

export const useSyncEventsMutation = (
  options?: MutationHookOptions<ISyncEventsResponse, ISyncEventsVars>
) => {
  return useMutation<ISyncEventsResponse, ISyncEventsVars>(
    MUTATION_SYNC_EVENTS,
    options
  )
}
