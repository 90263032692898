import { gql } from '@apollo/client'

import { FRAGMENT_CODING_TEST } from 'graphql/fragments/codingTestFragments'
import { CodingTest, RunTestCasesInput } from 'graphql/types'

export interface IRunTestCasesResponse {
  result: CodingTest
}

export interface IRunTestCasesVars {
  input: RunTestCasesInput
}

export const MUTATION_RUN_TEST_CASES = gql`
  mutation runTestCases($input: RunTestCasesInput!) {
    result: runTestCases(input: $input) {
      ...FragmentCodingTest
    }
  }

  ${FRAGMENT_CODING_TEST}
`
