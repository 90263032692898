import React from 'react'
import { useSearchParams } from 'react-router-dom'
import Select from 'react-select'

import {
  Button,
  EnumButtonVariants,
  Typography,
  EnumTypographyVariants,
} from '@insquad/tools'

import { TextSize } from 'common/components/TextSize/TextSize'

import { ReactComponent as MoonIcon } from './moonIcon.svg'
import { ReactComponent as PlayIcon } from './playIcon.svg'

import s from './CodingFooter.module.scss'

const getCurrentEnvironment = (
  environments: IEnvironmentOption[],
  environment: string
) => {
  return environments?.filter((env) => env.value === environment)
}

const getCurrentEnvironmentLabel = (
  environments: IEnvironmentOption[],
  environment: string
) => {
  const env = getCurrentEnvironment(environments || [], environment)
  return env[0]?.label
}

interface IEnvironmentOption {
  value: string
  label: string
}

export const CodingFooter: React.FC<{
  size: number
  environment: string
  environments?: IEnvironmentOption[]
  isMultiEnvironments: boolean
  isLoadingTestRunner: boolean
  isLoadingEnv: boolean
  increment: () => void
  decrement: () => void
  handleOpenModal: () => void
  handleRunTest: (isCompleted: boolean) => void
  onChangeEnvironment: (env: string) => void
}> = ({
  size,
  isLoadingTestRunner,
  environment,
  environments,
  isMultiEnvironments,
  isLoadingEnv,
  increment,
  decrement,
  handleOpenModal,
  handleRunTest,
  onChangeEnvironment,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const handleChangeOption = (option: IEnvironmentOption | null) => {
    if (option) {
      onChangeEnvironment(option.value)
    }
  }

  const handleClickRunTest = () => {
    handleRunTest(false)
  }

  return (
    <footer className={s.CodingFooter}>
      <div className={s.CodingFooter__switch}>
        {!isMultiEnvironments ? (
          <Typography
            variant={EnumTypographyVariants.P3}
            as="div"
            text={getCurrentEnvironmentLabel(environments || [], environment)}
            className={s.CodingFooter__switchLanguage}
          />
        ) : (
          <Select
            isLoading={isLoadingEnv}
            options={environments}
            value={getCurrentEnvironment(environments || [], environment)}
            classNamePrefix={'select'}
            onChange={handleChangeOption}
            isSearchable={false}
            menuPlacement="top"
            className={s.CodingFooter__selectLanguage}
          />
        )}

        <TextSize size={size} increment={increment} decrement={decrement} />
        {false && (
          <div className={s.CodingFooter__switchTheme}>
            <MoonIcon />
          </div>
        )}
      </div>
      <div className={s.CodingFooter__btns}>
        <Button
          variant={EnumButtonVariants.SECONDARY}
          iconLeft={<PlayIcon />}
          className={s.CodingFooter__btn}
          onClick={handleClickRunTest}
          isLoading={isLoadingTestRunner}
        >
          {isRuLocale ? 'Запустить' : 'Run'}
        </Button>
        <Button className={s.CodingFooter__btn} onClick={handleOpenModal}>
          {isRuLocale ? 'Отправить' : 'Submit'}
        </Button>
      </div>
    </footer>
  )
}
