import { ToastContainer } from 'react-toastify'

import { ViewRoutes } from 'routes'

function App() {
  return (
    <>
      <ViewRoutes />
      <ToastContainer
        autoClose={5000}
        closeOnClick={false}
        draggable={false}
        position="top-right"
        closeButton={false}
        limit={3}
        hideProgressBar
        newestOnTop
      />
    </>
  )
}

export default App
