import { QueryHookOptions, useQuery } from '@apollo/client'

import {
  QUERY_GET_ENVIRONMENTS,
  IGetEnvironmentsResponse,
} from '../gql/getEnvironments'

export const useEnvironmentsQuery = (
  options?: QueryHookOptions<IGetEnvironmentsResponse>
) => {
  return useQuery<IGetEnvironmentsResponse>(QUERY_GET_ENVIRONMENTS, options)
}

export const useGetEnvironments = () => {
  const { data, loading, error } = useEnvironmentsQuery()

  const environments = data?.result.map((item) => {
    return { value: item?.name || '', label: item?.publicName || '' }
  })

  return {
    environments: environments,
    error: error,
    isLoading: loading,
  }
}
