import { gql } from '@apollo/client'

import { Environment } from 'graphql/types'

export interface IGetEnvironmentsResponse {
  result: Environment[]
}

export const QUERY_GET_ENVIRONMENTS = gql`
  query getEnvironments {
    result: getEnvironments {
      name
      publicName
    }
  }
`
