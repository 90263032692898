import { RouteObject } from 'react-router-dom'

import { EnumRoutes } from 'common/enums/routesEnums'
import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'
import { ReportPage } from 'page/ReportPage/ReportPage'
import { SessionPage } from 'page/SessionPage/SessionPage'

export const VIEW_ROUTES: RouteObject[] = [
  {
    path: EnumRoutes.REPORT_ROUTE,
    element: <ReportPage />,
  },
  {
    path: EnumRoutes.SESSION_ROUTE,
    element: <SessionPage />,
  },
  {
    path: '*',
    element: <NotFoundLayout />,
  },
]
