import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useToggle } from '@insquad/tools'

import CodeEditorBox from 'common/components/CodeEditorBox/CodeEditorBox'
import TaskDescription from 'common/components/TaskDescription/TaskDescription'
import TestCases from 'common/components/TestCases/TestCases'
import Timeline from 'common/components/Timeline/Timeline'
import TitleTask from 'common/components/TitleTask/TitleTask'
import { Task, Event, EventType } from 'graphql/types'

import s from './CodingViewPage.module.scss'

interface ICodingViewPageProps {
  Task: Task
  activeTask: number
  events: Event[] | undefined
}

export const CodingViewPage: React.FC<ICodingViewPageProps> = ({
  Task,
  events,
  activeTask,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const isOpenDescription = useToggle()
  const [activeEvent, setActiveEvent] = useState((events?.length || 1) - 1)
  const [titleEvent, setTitleEvent] = useState('Final Code')
  const [currentPoint, setCurrentPoint] = useState(-1)
  const [position, setPosition] = useState(0)

  const handleClickTimeline = (index: number, titleEvent: string) => {
    setActiveEvent(index)
    setTitleEvent(titleEvent)
    isOpenDescription.unset()
  }

  useEffect(() => {
    setActiveEvent((events?.length ?? 1) - 1)
    setTitleEvent('Final Code')
    isOpenDescription.set()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Task, activeTask])

  const getStringCode = (activeEvent: number) => {
    if (activeEvent === 0) {
      return Task?.Coding?.initialCode
    }
    if (activeEvent === (events?.length ?? 1) - 1) {
      return Task?.Coding?.finalCode
    }
    if (events) {
      if (events[activeEvent]?.type === EventType.TestExecution) {
        return events[activeEvent]?.CodingTest?.code
      } else {
        return events[activeEvent]?.code
      }
    }
  }

  const handleActivePoint = (i: number) => {
    setCurrentPoint(i)
  }

  const handlePosition = (i: number) => {
    setPosition(i)
  }

  return (
    <>
      <div className={s.CodingViewPage__content}>
        <div className={s.CodingViewPage__border} />
        <TitleTask
          title={
            isRuLocale
              ? Task?.Coding?.publicNameRu || Task?.Coding?.publicName || ''
              : Task?.Coding?.publicName || ''
          }
          isSmall
          isSmallTextCircle
          isBorderHidden
          tags={Task?.Tags}
          score={Task?.score || 0}
          maxScore={Task?.maxScore || 0}
          duration={Task?.duration || 0}
          isAnimatedCircle={false}
        />
        <Timeline
          Events={events}
          handleClickTimeline={handleClickTimeline}
          activeTask={activeTask}
          activeEvent={activeEvent}
          handleActivePoint={handleActivePoint}
          currentPoint={currentPoint}
          handlePosition={handlePosition}
          position={position}
        />
        <TaskDescription
          title={'Task'}
          text={
            isRuLocale
              ? Task?.Coding?.textRu || Task?.Coding?.text || ''
              : Task?.Coding?.text || ''
          }
          handleClick={isOpenDescription.toggle}
          isOpen={isOpenDescription.value}
        />
        <div className={s.CodingViewPage__codeBox}>
          <CodeEditorBox
            title={titleEvent}
            text={getStringCode(activeEvent) || ''}
            environment={Task?.Coding?.environment || ''}
          />
          <TestCases
            title={titleEvent}
            Events={events}
            activeEvent={activeEvent}
            handleClickTimeline={handleClickTimeline}
            handleActivePoint={handleActivePoint}
            handlePosition={handlePosition}
          />
        </div>
      </div>
    </>
  )
}
