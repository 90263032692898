import { useEffect, useRef, useState, useMemo, MouseEvent } from 'react'

import clsx from 'clsx'

import {
  Button,
  EnumButtonVariants,
  EnumTypographyVariants,
  Typography,
  useInterval,
  useMediaQuery,
  useToggle,
  EnumBreakpoints,
} from '@insquad/tools'

import { ReactComponent as ChevronIcon } from 'assets/icons/simple/chevronIcon.svg'
import { Maybe, Event, EventType } from 'graphql/types'
import { getIsPassedTest } from 'utils/getIsPassedTest'

import { CodeLaunchPoint } from './CodeLaunchPoint/CodeLaunchPoint'
import { ReactComponent as PointerIcon } from './pointerIcon.svg'

import s from './Timeline.module.scss'

const getPositionIndexClick = (
  position: number,
  Events: Event[],
  lineWidth: number
) => {
  return Number(
    (((Events?.length ?? 1) - 1) * (position / lineWidth)).toFixed()
  )
}

const getPositionPlay = (index: number, Events: Event[], lineWidth: number) => {
  return Number((index / ((Events?.length ?? 1) - 1)) * lineWidth)
}

const getMobileText = (
  index: number,
  mobileEventArray: (
    | {
        event: Event | never[]
        index: number
        position: number
      }
    | undefined
  )[]
) => {
  if (index === 0) {
    return 'Start Code'
  }

  if (index === mobileEventArray.length - 1) {
    return 'Finish Code'
  }

  return `Code Launch #${index}`
}

interface ITimelineProps {
  Events: Maybe<Event[]> | undefined
  activeTask: number
  activeEvent: number
  currentPoint: number
  position: number

  handlePosition: (index: number) => void
  handleActivePoint: (index: number) => void
  handleClickTimeline: (index: number, title: string) => void
}

const Timeline: React.FC<ITimelineProps> = ({
  Events,
  activeTask,
  activeEvent,
  currentPoint,
  position,
  handleClickTimeline,
  handleActivePoint,
  handlePosition,
}) => {
  const lineRef = useRef<HTMLDivElement>(null)

  const [lineWidth, setLineWidth] = useState(0)

  const [hover, setHover] = useState(0)
  const [mobileEventIndex, setMobileEventIndex] = useState(0)
  const isHoverPoint = useToggle(true)
  const isPlay = useToggle()
  const isMobile = useMediaQuery(EnumBreakpoints.BREAKPOINT_SM, 'max')

  const [pointArray, mobileEventArray] = useMemo(() => {
    const pointArray = Events?.map((event, i) => {
      if (event.type === EventType.TestExecution) {
        return {
          event: event,
          index: i,
          position: Number((i / (Events.length - 1)).toFixed(2)),
        }
      }
    }).filter((item) => item)

    const mobileEventArray = [
      { event: Events ? Events[0] : [], index: 0, position: 0 },
      ...(pointArray || []),
    ]

    return [pointArray?.slice(0, -1) || [], mobileEventArray]
  }, [Events])

  const setLineWidthState = () => {
    if (lineRef.current) {
      setLineWidth(lineRef.current.offsetWidth)
    }
  }

  const handleClickStart = () => {
    if (activeEvent === (Events?.length || -1) - 1) {
      handleClickTimeline(0, 'Start Code')
      handleActivePoint(-1)
      handlePosition(0)
    }
    isPlay.toggle()
  }

  const handleClickFinish = () => {
    isPlay.setValue(false)
    handleClickTimeline((Events?.length ?? 1) - 1, 'Final Code')
    handleActivePoint(-1)
    handlePosition(0)
  }

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    isPlay.setValue(false)
    handleActivePoint(-1)
    handlePosition(e.clientX - e.currentTarget.offsetLeft - 5)
    handleClickTimeline(
      getPositionIndexClick(
        e.clientX - e.currentTarget.offsetLeft - 5,
        Events || [],
        lineWidth
      ),
      'Code'
    )
  }

  const handleEnter = (e: MouseEvent<HTMLDivElement>) => {
    setHover(e.clientX - e.currentTarget.offsetLeft - 7)
  }

  const handleLeave = () => {
    setHover(0)
  }

  const handleClickPoint = (
    e: MouseEvent<HTMLDivElement>,
    i: number,
    index: number
  ) => {
    e.stopPropagation()
    isPlay.setValue(false)
    handlePosition(0)
    handleActivePoint(i)
    handleClickTimeline(index, `Code Launch #${i + 1}`)
  }

  const handleClickChevron = (increment: number, index: number) => {
    if (index + increment <= 0) {
      setMobileEventIndex(0)

      if (mobileEventArray) {
        handleClickTimeline(mobileEventArray[0]?.index ?? 0, 'Start Code')
      }
    } else if (index + increment >= mobileEventArray.length - 1) {
      setMobileEventIndex(mobileEventArray.length - 1)

      if (mobileEventArray) {
        handleClickTimeline(
          mobileEventArray[mobileEventArray.length - 1]?.index ?? 0,
          'Finish Code'
        )
      }
    } else {
      setMobileEventIndex(index + increment)

      if (mobileEventArray) {
        handleClickTimeline(
          mobileEventArray[index + increment]?.index ?? 0,
          `Code Launch #${index + increment}`
        )
      }
    }
  }

  useEffect(() => {
    setLineWidthState()
    setMobileEventIndex(mobileEventArray.length - 1)
    window.addEventListener('resize', setLineWidthState)
    return () => {
      window.removeEventListener('resize', setLineWidthState)
    }
  }, [])

  useEffect(() => {
    setMobileEventIndex(mobileEventArray.length - 1)
    handleActivePoint(-1)
    handlePosition(0)
    isPlay.setValue(false)
  }, [activeTask])

  useInterval(
    () => {
      const pointIndex = pointArray.findIndex(
        (point) => point?.index === activeEvent + 1
      )

      if (activeEvent === (Events?.length ?? 0) - 2) {
        return handleClickFinish()
      }

      if ((pointIndex && pointIndex > -1) || pointIndex === 0) {
        handlePosition(0)
        handleActivePoint(pointIndex)

        if (pointArray) {
          return handleClickTimeline(
            pointArray[pointIndex]?.index || 0,
            `Code launch #${pointIndex + 1}`
          )
        }
      } else {
        handleActivePoint(-1)
        handlePosition(
          getPositionPlay(activeEvent + 1, Events || [], lineWidth)
        )
      }
      handleClickTimeline(activeEvent + 1, 'Code')
    },
    isPlay.value ? 500 : null
  )

  return (
    <>
      {isMobile ? (
        <div className={s.Timeline_mobile}>
          <div
            className={clsx(s.Timeline__iconWrapper, {
              [s.Timeline__iconWrapper_disabled]: mobileEventIndex === 0,
            })}
            onClick={() => handleClickChevron(-1, mobileEventIndex)}
          >
            <ChevronIcon
              className={clsx(s.Timeline__icon, s.Timeline__icon_left, {
                [s.Timeline__icon_disabled]: mobileEventIndex === 0,
              })}
            />
          </div>

          <div className={s.Timeline__box}>
            <Typography
              variant={EnumTypographyVariants.P2}
              text={getMobileText(mobileEventIndex, mobileEventArray)}
            />
            {!!Events?.length && Events[activeEvent]?.CodingTest && (
              <Typography
                variant={EnumTypographyVariants.P3}
                text={`${Events[activeEvent]?.CodingTest?.score}/${Events[activeEvent]?.CodingTest?.maxScore}`}
                className={clsx(s.Timeline__boxTag, {
                  [s.Timeline__boxTag_green]: getIsPassedTest(
                    Events[activeEvent]?.CodingTest?.score || 0,
                    Events[activeEvent]?.CodingTest?.maxScore || 0
                  ),
                  [s.Timeline__boxTag_red]: !getIsPassedTest(
                    Events[activeEvent]?.CodingTest?.score || 0,
                    Events[activeEvent]?.CodingTest?.maxScore || 0
                  ),
                })}
              />
            )}
          </div>
          <div
            className={clsx(s.Timeline__iconWrapper, {
              [s.Timeline__iconWrapper_disabled]:
                mobileEventIndex === mobileEventArray.length - 1,
            })}
            onClick={() => handleClickChevron(1, mobileEventIndex)}
          >
            <ChevronIcon
              className={clsx(s.Timeline__icon, s.Timeline__icon_right, {
                [s.Timeline__icon_disabled]:
                  mobileEventIndex === mobileEventArray.length - 1,
              })}
            />
          </div>
        </div>
      ) : (
        <div className={s.Timeline}>
          <Button
            variant={
              activeEvent === 0
                ? EnumButtonVariants.PRIMARY
                : EnumButtonVariants.SECONDARY
            }
            className={clsx({ [s.Timeline__button]: activeEvent !== 0 })}
            onClick={!isPlay.value ? handleClickStart : isPlay.unset}
          >
            {!isPlay.value ? 'Start' : 'Stop'}
          </Button>
          <div
            className={s.Timeline__lineWrapper}
            onMouseMove={handleEnter}
            onMouseLeave={handleLeave}
            onClick={handleClick}
          >
            <div className={s.Timeline__line} ref={lineRef}>
              {!!pointArray &&
                pointArray.map((point, i) => (
                  <CodeLaunchPoint
                    key={i}
                    order={i}
                    index={point?.index || 0}
                    isPassed={getIsPassedTest(
                      point?.event.CodingTest?.score || 0,
                      point?.event.CodingTest?.maxScore || 0
                    )}
                    isActivePoint={i === currentPoint}
                    textTooltip={`
                      ${point?.event.CodingTest?.score}/${point?.event.CodingTest?.maxScore}`}
                    position={(point?.position || 0) * lineWidth}
                    handleEnterPoint={isHoverPoint.unset}
                    handleLeavePoint={isHoverPoint.set}
                    handleClickPoint={handleClickPoint}
                  />
                ))}

              {!!position && (
                <div
                  className={clsx(s.Timeline__point, s.Timeline__point_blue)}
                  style={{ left: `${position}px` }}
                  onMouseEnter={isHoverPoint.unset}
                  onMouseLeave={isHoverPoint.set}
                ></div>
              )}
              {!!hover && isHoverPoint.value && (
                <PointerIcon
                  className={s.Timeline__pointer}
                  style={{ left: `${hover}px` }}
                />
              )}
            </div>
          </div>

          <Button
            variant={
              activeEvent === (Events?.length || 0) - 1
                ? EnumButtonVariants.PRIMARY
                : EnumButtonVariants.SECONDARY
            }
            className={clsx(s.Timeline__button_padding, {
              [s.Timeline__button]: activeEvent !== (Events?.length || 0) - 1,
            })}
            onClick={handleClickFinish}
          >
            Final Code
          </Button>
        </div>
      )}
    </>
  )
}

export default Timeline
