import { gql } from '@apollo/client'

import { SyncEventsInput } from 'graphql/types'

export interface ISyncEventsResponse {
  result: boolean
}

export interface ISyncEventsVars {
  input: SyncEventsInput
}

export const MUTATION_SYNC_EVENTS = gql`
  mutation syncEvents($input: SyncEventsInput!) {
    result: syncEvents(input: $input)
  }
`
