import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'
import rehypeHighlight from 'rehype-highlight'

import {
  Modal,
  EnumTypographyVariants,
  Typography,
  useToggle,
} from '@insquad/tools'

import { ReactComponent as ChevronIcon } from 'assets/icons/chevronIcon.svg'
import { Question } from 'graphql/types'

import Answer from '../Answer/Answer'
import QuestionBox from '../QuestionBox/QuestionBox'

import {
  INITIAL_OPTION,
  INITIAL_OPTION_RU,
  QuestionListSelect,
  SelectOption,
} from './QuestionListSelect/QuestionListSelect'

import s from './QuestionList.module.scss'

export interface IQuestionListProps {
  questions: Question[]
  titleModal: string
}

const QuestionList: React.FC<IQuestionListProps> = ({
  questions = [],
  titleModal,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'

  const modalToggler = useToggle()
  const [questionsList, setQuestionsList] = useState(questions)
  const [item, setItem] = useState(0)
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(
    !isRuLocale ? INITIAL_OPTION : INITIAL_OPTION_RU
  )

  const handleClickModal = (number: number) => {
    modalToggler.set()
    setItem(number - 1)
  }

  const changeQuestion = (direction: number) => {
    if (item + direction < 0) {
      return setItem(0)
    }

    if (item + direction > questions.length - 1) {
      return setItem(questions.length - 1)
    }

    setItem((item) => item + direction)
  }

  const handleChangeOption = (option: SelectOption | null) => {
    setSelectedOption(option)
    setQuestionsList(option ? questions.filter(option.filter) : questions)
  }

  return (
    <>
      <QuestionListSelect
        value={selectedOption}
        onChange={handleChangeOption}
        className={s.QuestionList__selectContainer}
      />

      <ul className={s.QuestionList}>
        {!!questionsList &&
          questionsList.map((question, i) => (
            <li key={i} className={s.QuestionList__item}>
              <QuestionBox
                isPassed={question?.isPassed ?? false}
                number={
                  questions
                    ? questions?.findIndex((q) => q.id === question.id) + 1
                    : 0
                }
                text={
                  !isRuLocale
                    ? question.title
                    : question.titleRu ?? question.title
                }
                handleClickModal={handleClickModal}
              />
            </li>
          ))}
      </ul>
      <Modal
        isOpen={modalToggler.value}
        onClose={modalToggler.unset}
        title={titleModal}
        enableCloseIcon
        classes={{
          titleClassName: s.QuestionList__modalTitle,
          bodyClassName: s.QuestionList__modalBody,
          contentClassName: s.QuestionList__modalContent,
        }}
      >
        <div className={s.QuestionList__modalSlider}>
          <ChevronIcon
            className={clsx(
              s.QuestionList__modalIcon,
              s.QuestionList__modalIcon_left,
              { [s.QuestionList__modalIcon_disabled]: item === 0 }
            )}
            onClick={() => changeQuestion(-1)}
          />

          <Typography
            variant={EnumTypographyVariants.H2}
            text={`${item + 1}/${questions?.length}`}
            className={clsx(
              {
                [s.QuestionList__sliderText_green]: !!questions?.length
                  ? questions[item].isPassed
                  : false,
              },
              {
                [s.QuestionList__sliderText_red]: !!questions?.length
                  ? !questions[item].isPassed
                  : false,
              },
              s.QuestionList__sliderText
            )}
          />

          <ChevronIcon
            className={clsx(
              s.QuestionList__modalIcon,
              s.QuestionList__modalIcon_right,
              {
                [s.QuestionList__modalIcon_disabled]:
                  item + 1 === questions?.length,
              }
            )}
            onClick={() => changeQuestion(1)}
          />
        </div>

        <Typography
          variant={EnumTypographyVariants.H2}
          text={
            !isRuLocale
              ? `${questions[item]?.title || ''}`
              : `${questions[item]?.titleRu || questions[item]?.title || ''}`
          }
          className={s.QuestionList__questionTitle}
        />
        {questions[item]?.text && (
          <ReactMarkdown
            className={s.QuestionList__questionText}
            rehypePlugins={[[rehypeHighlight, { ignoreMissing: true }]]}
          >
            {!isRuLocale
              ? questions[item].text || ''
              : questions[item].textRu || ''}
          </ReactMarkdown>
        )}
        {!!questions?.length && (
          <ul className={s.QuestionList__questionList}>
            {questions[item].Variants?.map((answer, i) => (
              <Answer
                key={i}
                text={!isRuLocale ? answer.text : answer.textRu ?? answer.text}
                isCorrect={answer.isCorrect ?? false}
                isSelected={answer.isSelected ?? false}
              />
            ))}
          </ul>
        )}
      </Modal>
    </>
  )
}

export default QuestionList
