import React from 'react'

import clsx from 'clsx'

import { Tag, EnumTypographyVariants, Typography } from '@insquad/tools'

import s from './QuestionBox.module.scss'

export interface IQuestionBoxProps {
  number: number
  text: string
  isPassed: boolean
  handleClickModal: (number: number) => void
}

const QuestionBox: React.FC<IQuestionBoxProps> = ({
  isPassed,
  text,
  number,
  handleClickModal,
}) => {
  return (
    <div
      className={clsx(
        s.QuestionBox,
        { [s.QuestionBox_green]: isPassed },
        { [s.QuestionBox_red]: !isPassed }
      )}
      onClick={() => handleClickModal(number)}
    >
      <div className={s.QuestionBox__wrapperTag}>
        <Typography
          variant={EnumTypographyVariants.H4}
          as="div"
          text={number}
          className={s.QuestionBox__number}
        />

        <Tag
          text={isPassed ? 'Correct' : 'Incorrect'}
          className={clsx(
            s.QuestionBox__tag,
            { [s.QuestionBox__tag_green]: isPassed },
            { [s.QuestionBox__tag_red]: !isPassed }
          )}
        />
      </div>

      <Typography
        variant={EnumTypographyVariants.H4}
        text={text}
        className={s.QuestionBox__text}
      />
    </div>
  )
}

export default QuestionBox
