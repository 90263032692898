import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import {
  Tag,
  EnumTypographyVariants,
  Typography,
  useToggle,
} from '@insquad/tools'

import { ReactComponent as ChevronIcon } from 'assets/icons/chevronIcon.svg'
import { Maybe } from 'graphql/types'

import s from './TestCase.module.scss'

interface ITestCaseProps {
  isCorrect: boolean
  isHiddenDescription?: boolean
  name: Maybe<string> | undefined
  expectedValue: Maybe<string> | undefined
  output: Maybe<string> | undefined
  returnedValue: Maybe<string> | undefined
}

const TestCase: React.FC<ITestCaseProps> = ({
  isCorrect,
  isHiddenDescription,
  name,
  expectedValue,
  returnedValue,
  output,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const IsDetailed = useToggle()

  if (isHiddenDescription) {
    return (
      <div className={s.TestCase}>
        <div className={clsx(s.TestCase__header, s.TestCase__header_disabled)}>
          <Typography
            variant={EnumTypographyVariants.P2}
            text={`Test case ${name || ''}`}
            as="div"
          />
          <div
            className={clsx(
              s.TestCase__tagWrapper,
              s.TestCase__tagWrapper_disabled
            )}
          >
            <Tag
              text={isCorrect ? 'Passed' : 'Failed'}
              className={clsx(
                s.TestCase__tag,
                { [s.TestCase__tag_green]: isCorrect },
                { [s.TestCase__tag_red]: !isCorrect }
              )}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={s.TestCase}>
      <div className={s.TestCase__header} onClick={IsDetailed.toggle}>
        <Typography
          variant={EnumTypographyVariants.P2}
          text={`Test case ${name || ''}`}
          as="div"
        />
        <div className={s.TestCase__tagWrapper}>
          <Tag
            text={isCorrect ? 'Passed' : 'Failed'}
            className={clsx(
              s.TestCase__tag,
              { [s.TestCase__tag_green]: isCorrect },
              { [s.TestCase__tag_red]: !isCorrect }
            )}
          />
          <div className={s.TestCase__iconWrapper}>
            <ChevronIcon
              className={clsx(s.TestCase__icon, {
                [s.TestCase__icon_open]: IsDetailed.value,
              })}
            />
          </div>
        </div>
      </div>
      {IsDetailed.value && (
        <>
          {expectedValue && (
            <div>
              <Typography
                variant={EnumTypographyVariants.P3}
                text={!isRuLocale ? 'Expected Value' : 'Ожидаемое значение'}
              />
              <pre className={s.TestCase__text}>{expectedValue}</pre>
            </div>
          )}
          {returnedValue && (
            <div>
              <Typography
                variant={EnumTypographyVariants.P3}
                text={!isRuLocale ? 'Returned Value' : 'Возвращаемое значение'}
              />
              <pre className={s.TestCase__text}>{returnedValue}</pre>
            </div>
          )}
          {output && (
            <div>
              <Typography
                variant={EnumTypographyVariants.P3}
                text={!isRuLocale ? 'Output' : 'Вывод'}
              />
              <pre className={s.TestCase__text}>{output}</pre>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default TestCase
