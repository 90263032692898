import { gql } from '@apollo/client'

export const FRAGMENT_EVENT_FULL = gql`
  fragment FragmentEventFull on Event {
    id
    type
    code
    trackedAt
    CodingTest {
      id
      code
      score
      maxScore
      CodingTestCases {
        id
        name
        isVisible
        expectedValue
        returnedValue
        passed
        output
      }
    }
  }
`
