import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import {
  Button,
  EnumButtonVariants,
  EnumTypographyVariants,
  Typography,
} from '@insquad/tools'

import { Event, EventType, Maybe } from 'graphql/types'
import { getIsPassedTest } from 'utils/getIsPassedTest'

import TestCase from './TestCase/TestCase'

import s from './TestCases.module.scss'

const getScore = (Events: Maybe<Event[]>, activeEvent: number) => {
  if (Events) {
    return `${Events[activeEvent].CodingTest?.score}/${Events[activeEvent].CodingTest?.maxScore}`
  }
}

interface ITestCasesProps {
  Events?: Maybe<Event[]>
  activeEvent: number
  title: string

  handleActivePoint: (index: number) => void
  handlePosition: (index: number) => void
  handleClickTimeline: (index: number, title: string) => void
}

const TestCases: React.FC<ITestCasesProps> = ({
  Events,
  activeEvent,
  title,
  handleClickTimeline,
  handleActivePoint,
  handlePosition,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const testLaunchIndex = useMemo(() => {
    return Events?.map((event, i) => {
      if (event.type === EventType.TestExecution) {
        return i
      }
    }).filter((item) => item)
  }, [Events])

  const handleGoNextLaunchCode = (activeEvent: number) => {
    const nextTest = testLaunchIndex?.find((i) => {
      return (i ?? 0) > activeEvent
    })
    if (testLaunchIndex) {
      handleActivePoint(testLaunchIndex?.indexOf(nextTest))
      handlePosition(0)
      handleClickTimeline(
        nextTest || 0,
        `Code Launch #${testLaunchIndex?.indexOf(nextTest) + 1}`
      )
    }
  }

  if (!Events) {
    return null
  }

  return (
    <div className={s.TestCases}>
      <Typography
        variant={EnumTypographyVariants.H3}
        as="div"
        className={s.TestCases__title}
      >
        {title} test cases
        {Events[activeEvent]?.CodingTest && (
          <Typography
            variant={EnumTypographyVariants.P3}
            text={getScore(Events, activeEvent)}
            className={clsx(s.TestCases__tag, {
              [s.TestCases__tag_green]: getIsPassedTest(
                Events[activeEvent].CodingTest?.score || 0,
                Events[activeEvent].CodingTest?.maxScore || 0
              ),
              [s.TestCases__tag_red]: !getIsPassedTest(
                Events[activeEvent].CodingTest?.score || 0,
                Events[activeEvent].CodingTest?.maxScore || 0
              ),
            })}
          />
        )}
      </Typography>
      <div className={s.TestCases__list}>
        {!!Events[activeEvent]?.CodingTest?.CodingTestCases?.length ? (
          (Events[activeEvent].CodingTest?.CodingTestCases || []).map(
            (test, i) => (
              <TestCase
                key={test.id}
                isCorrect={test.passed}
                name={`#${i + 1}`}
                expectedValue={test.expectedValue}
                output={test.output}
                returnedValue={test.returnedValue}
              />
            )
          )
        ) : (
          <div className={s.TestCases__prompt}>
            <Typography
              variant={EnumTypographyVariants.P2}
              text={
                !isRuLocale
                  ? 'There is no launch at this moment'
                  : 'На данный момент запуска нет'
              }
            />
            <Button
              variant={EnumButtonVariants.SECONDARY}
              onClick={() => handleGoNextLaunchCode(activeEvent)}
            >
              {!isRuLocale
                ? 'Go to the next code launch'
                : 'Перейдите к следующему запуску кода'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default TestCases
