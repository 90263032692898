import {
  EnumTypographyVariants,
  Typography,
  EnumBreakpoints,
  useMediaQuery,
  useWindowSize,
} from '@insquad/tools'
import Editor from '@monaco-editor/react'

import s from './CodeEditorBox.module.scss'

interface ICodeEditorBoxProps {
  title: string
  text: string
  environment: string
}

const CodeEditorBox: React.FC<ICodeEditorBoxProps> = ({
  title,
  text,
  environment,
}) => {
  const isTablet = useMediaQuery(EnumBreakpoints.BREAKPOINT_LG, 'max')

  const { width } = useWindowSize()

  return (
    <Typography
      variant={EnumTypographyVariants.H3}
      as="div"
      className={s.CodeEditorBox}
    >
      {title}
      <div className={s.CodeEditorBox__codeWrapper}>
        <Editor
          height={!isTablet ? '510px' : '370px'}
          language={environment}
          value={text}
          width={!isTablet ? `${width * 0.48}px` : '100%'}
          options={{
            readOnly: true,
            scrollbar: { vertical: 'hidden' },
            minimap: { enabled: false },
            scrollBeyondLastLine: false,
            hideCursorInOverviewRuler: true,
            overviewRulerBorder: false,
          }}
        />
      </div>
    </Typography>
  )
}

export default CodeEditorBox
