import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Button,
  EnumButtonVariants,
  Modal,
  EnumTypographyVariants,
  Typography,
  useIsMobile,
  useToggle,
  EnumToastVariants,
} from '@insquad/tools'

import Header from 'common/components/Header/Header'
import { TimeCounter } from 'common/components/TimeCounter/TimeCounter'
import { openToast } from 'common/components/Toast/Toast'
import { useAnswerQuestionMutation } from 'graphql/mutations/answer/hooks/useAnswerQuestionMutation'
import { Maybe, Question, QuestionType } from 'graphql/types'

import { Question as QuestionContent } from './Question/Question'
import { TaskFinished } from './TaskFinished/TaskFinished'

import s from './QuizPage.module.scss'

export const QuizPage: React.FC<{
  CurrentQuestion?: Maybe<Question>
  questionsCount: number
  currentQuestionPosition: number
  headerTitle: string
  diffTime: number
  sessionId: string

  handleFinishSaveEvents: () => void
}> = ({
  CurrentQuestion,
  questionsCount,
  currentQuestionPosition,
  headerTitle,
  diffTime,
  sessionId,
  handleFinishSaveEvents,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const modalToggler = useToggle()
  const isMobile = useIsMobile()

  const [counter, setCounter] = useState(60)

  const [saveAnswer, { loading }] = useAnswerQuestionMutation({
    onCompleted() {
      modalToggler.unset()
    },
    onError() {
      openToast({
        text: 'Failed answer to question. Please, reload page',
        variant: EnumToastVariants.ERROR,
      })
    },
  })

  const startedAt = Date.parse(CurrentQuestion?.startedAt || '') / 1000
  const TIME_LIMIT = CurrentQuestion?.timeLimit || 0
  const isLastQuestion = currentQuestionPosition === questionsCount

  const time = useMemo(() => {
    return (
      (CurrentQuestion?.startedAt
        ? startedAt + diffTime - Date.now() / 1000
        : 0) + TIME_LIMIT
    )
  }, [CurrentQuestion?.id, diffTime, TIME_LIMIT])

  const handleSave = (answers: string[]) => {
    if (CurrentQuestion) {
      saveAnswer({
        variables: {
          input: {
            questionGuid: CurrentQuestion?.id,
            variantGuids: answers,
          },
        },
      })
    }
  }

  const handlePostMessage = () => {
    window.parent.postMessage({
      type: 'session-completed',
      session: sessionId,
    })
  }

  useEffect(() => {
    if (time < 0) {
      handleSave([])
    }
  }, [time])

  useEffect(() => {
    setCounter(Number(time.toFixed()))
  }, [time, CurrentQuestion])

  useEffect(() => {
    const timer = setInterval(() => setCounter(counter - 1), 1000)
    if (counter === 0) {
      if (isLastQuestion) {
        handleFinishSaveEvents()
      }
      handleSave([])
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [counter])

  return (
    <>
      {!isMobile ? (
        <Header title={headerTitle} />
      ) : (
        <header className={s.QuizPage__header_fixed}>
          <Typography variant={EnumTypographyVariants.P2} text={headerTitle} />

          {currentQuestionPosition && (
            <TimeCounter
              timer={counter}
              title={`${
                isRuLocale ? 'Вопрос' : 'Question'
              } ${currentQuestionPosition}/${questionsCount}`}
            />
          )}
        </header>
      )}
      <div className={s.QuizPage}>
        {CurrentQuestion && (
          <QuestionContent
            handleOpenModal={modalToggler.set}
            title={
              !isRuLocale
                ? CurrentQuestion?.title ?? ''
                : CurrentQuestion?.titleRu ?? CurrentQuestion?.title ?? ''
            }
            text={
              !isRuLocale
                ? CurrentQuestion?.text ?? ''
                : CurrentQuestion?.textRu ?? CurrentQuestion?.text ?? ''
            }
            timer={counter}
            isMultipleAnswer={CurrentQuestion?.type === QuestionType.Multiple}
            currentQuestion={currentQuestionPosition || 0}
            amountQuestion={questionsCount || 0}
            variants={CurrentQuestion?.Variants || []}
            handleSave={handleSave}
            handleFinishSaveEvents={handleFinishSaveEvents}
            handlePostMessage={handlePostMessage}
            isLoading={loading}
          />
        )}
        {!CurrentQuestion && <TaskFinished />}
      </div>

      <Modal
        isOpen={modalToggler.value}
        onClose={modalToggler.unset}
        title={
          isRuLocale
            ? 'Вы уверены, что хотите пропустить этот вопрос?'
            : 'Are you sure you want to skip this question?'
        }
        classes={{
          bodyClassName: s.QuizPage__modal,
          contentClassName: s.QuizPage__modalContent,
        }}
        enableCloseIcon
      >
        <Typography
          variant={EnumTypographyVariants.P2}
          text={
            isRuLocale
              ? 'Вопрос будет не засчитан. Вы не сможете вернуться к нему позже.'
              : `The question will fail. You won't be able to come back to it later.`
          }
        />
        <div className={s.QuizPage__modalButtonBox}>
          <Button
            onClick={modalToggler.unset}
            className={s.QuizPage__modalButton}
          >
            {isRuLocale ? 'Вернуться к вопросу' : ' Back to question'}
          </Button>
          <Button
            variant={EnumButtonVariants.SECONDARY}
            className={s.QuizPage__modalButton}
            onClick={() => handleSave([])}
          >
            {isRuLocale ? 'Пропустить' : ' Skip'}
          </Button>
        </div>
      </Modal>
    </>
  )
}
