import { gql } from '@apollo/client'

export const FRAGMENT_CODING_TEST = gql`
  fragment FragmentCodingTest on CodingTest {
    id
    code
    score
    maxScore
    CodingTestCases {
      id
      name
      isVisible
      expectedValue
      returnedValue
      passed
      output
    }
  }
`
