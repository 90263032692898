import { ApolloClient, from, InMemoryCache } from '@apollo/client'

import { errorLink } from './links/errorLink'
import { httpLink } from './links/httpLink'
import { retryLink } from './links/retryLink'

function createApolloClient() {
  return new ApolloClient({
    link: from([errorLink, retryLink, httpLink]),
    cache: new InMemoryCache(),
  })
}

export const apolloClient = createApolloClient()
