import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'

import {
  Spinner,
  useInterval,
  useToggle,
  EnumToastVariants,
} from '@insquad/tools'

import Header from 'common/components/Header/Header'
import { openToast } from 'common/components/Toast/Toast'
import { NotFoundLayout } from 'common/layouts/NotFoundLayout/NotFoundLayout'
import { useSyncEventsMutation } from 'graphql/mutations/events/hooks/useSyncEventsMutation'
import { useSessionInfo } from 'graphql/queries/session/hooks/useSessionQuery'
import { EventInput, EventType, TaskStatus, TaskType } from 'graphql/types'
import { CodingPage } from 'page/SessionPage/CodingPage/CodingPage'
import { QuizPage } from 'page/SessionPage/QuizPage/QuizPage'

import { TaskFinished } from './QuizPage/TaskFinished/TaskFinished'

import s from './SessionPage.module.scss'

export const SessionPage = () => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const { sessionGuid = '' } = useParams()
  const [activeTask, setActiveTask] = useState(0)
  const isFinishedTask = useToggle()
  const [events, setEvents] = useState<EventInput[]>([])
  const timerSendEvents = useToggle(true)

  const {
    id,
    publicName,
    publicNameRu,
    isLoading,
    serverTime,
    Tasks,
    status,
    error,
    startPolling,
    stopPolling,
  } = useSessionInfo(sessionGuid)

  const [saveEvents] = useSyncEventsMutation({
    onCompleted() {
      setEvents([])
    },
  })

  const handleSaveEvents = () => {
    if (
      !!Tasks.length &&
      status !== TaskStatus.Finished &&
      !!events.length &&
      !isFinishedTask.value
    ) {
      saveEvents({
        variables: {
          input: {
            taskGuid: Tasks[activeTask]?.id || '',
            events: events,
          },
        },
      })
    }
  }

  const handleFinishSaveEvents = () => {
    handleSaveEvents()
    timerSendEvents.unset()
  }

  const handleGetNextTask = () => {
    startPolling(500)
  }

  const handleChangeActiveTask = () => {
    if (activeTask === Tasks.length - 1) {
      isFinishedTask.set()
    } else {
      setActiveTask((activeTask) => activeTask + 1)
    }
  }

  useInterval(
    () => {
      handleSaveEvents()
    },
    timerSendEvents.value ? 5000 : null
  )

  useEffect(() => {
    const handleEvent = (name: EventType) => {
      const date = new Date()
      const currentDate = date.toISOString()

      setEvents((events) => [...events, { trackedAt: currentDate, type: name }])
    }

    window.addEventListener('blur', () => handleEvent(EventType.Blur))
    window.addEventListener('focus', () => handleEvent(EventType.Focus))
    return () => {
      window.removeEventListener('blur', () => handleEvent(EventType.Blur))
      window.removeEventListener('focus', () => handleEvent(EventType.Focus))
    }
  }, [])

  useEffect(() => {
    if (status === TaskStatus.Finished || Tasks[activeTask]?.Coding) {
      stopPolling()
    }
  }, [Tasks, activeTask, status, stopPolling])

  useEffect(() => {
    if (Tasks[activeTask]?.status === TaskStatus.Finished) {
      setActiveTask((activeTask) => activeTask + 1)
    }
  }, [isLoading, Tasks, activeTask])

  const diffTime = useMemo(() => {
    return (Date.now() - serverTime) / 1000
  }, [serverTime])

  const currentTask = useMemo(() => {
    return Tasks.filter((task) => {
      return task?.Coding
    })[0]
  }, [Tasks])

  const renderCurrentView = useCallback(() => {
    if (isFinishedTask.value || status === TaskStatus.Finished) {
      return (
        <>
          <Header
            title={!isRuLocale ? publicName : publicNameRu || publicName}
          />
          <TaskFinished />
        </>
      )
    }
    if (Tasks[activeTask]?.type === TaskType.Quiz) {
      return (
        <QuizPage
          sessionId={id}
          headerTitle={!isRuLocale ? publicName : publicNameRu || publicName}
          CurrentQuestion={Tasks[activeTask].Quiz?.CurrentQuestion}
          questionsCount={Tasks[activeTask].Quiz?.questionsCount || 0}
          currentQuestionPosition={
            Tasks[activeTask].Quiz?.currentQuestionPosition || 0
          }
          diffTime={diffTime}
          handleFinishSaveEvents={handleFinishSaveEvents}
        />
      )
    }

    if (Tasks[activeTask]?.type === TaskType.Coding) {
      if (!currentTask) {
        return (
          <>
            <Header
              title={!isRuLocale ? publicName : publicNameRu || publicName}
            />
            <TaskFinished />
          </>
        )
      }
      return (
        <CodingPage
          headerTitle={!isRuLocale ? publicName : publicNameRu || publicName}
          Task={currentTask}
          currentTask={activeTask + 1}
          amountTasks={Tasks.length}
          diffTime={diffTime}
          handleFinishSaveEvents={handleFinishSaveEvents}
          handleChangeActiveTask={handleChangeActiveTask}
          handleGetNextTask={handleGetNextTask}
        />
      )
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Tasks, activeTask, publicName, diffTime, isFinishedTask.value])

  if (error) {
    openToast({
      text: `${error.message}`,
      variant: EnumToastVariants.ERROR,
    })

    return <NotFoundLayout />
  }

  return (
    <>
      {isLoading && (
        <div className={s.SessionPage__spinner}>
          <Spinner size="xl" />
        </div>
      )}
      {!isLoading && renderCurrentView()}
    </>
  )
}
