import { gql } from '@apollo/client'

import { FRAGMENT_TASK_FULL, FRAGMENT_TASK_PASS } from './taskFragment'

export const FRAGMENT_SESSION_FULL = gql`
  fragment FragmentSessionFull on Session {
    id
    externalId
    name
    publicName
    publicNameRu
    score
    maxScore
    status
    duration
    netTime
    startedAt
    finishedAt
    Tasks {
      ...FragmentTaskFull
    }
  }

  ${FRAGMENT_TASK_FULL}
`

export const FRAGMENT_SESSION_PASS = gql`
  fragment FragmentSessionPass on Session {
    id
    externalId
    name
    publicName
    publicNameRu
    score
    maxScore
    status
    duration
    netTime
    startedAt
    finishedAt
    System {
      serverTime
    }
    Tasks {
      ...FragmentTaskPass
    }
  }

  ${FRAGMENT_TASK_PASS}
`
