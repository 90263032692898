import React from 'react'
import ReactMarkdown from 'react-markdown'

import clsx from 'clsx'

import { Tag } from '@insquad/tools'

import s from './Answer.module.scss'

interface IAnswerProps {
  text: string
  isCorrect: boolean
  isSelected: boolean
}

const Answer: React.FC<IAnswerProps> = ({ text, isCorrect, isSelected }) => {
  const isVisibleTag = isSelected || (!isSelected && isCorrect)
  return (
    <li
      className={clsx(
        s.Answer,
        { [s.Answer_green]: isCorrect && isSelected },
        { [s.Answer_red]: !isCorrect && isSelected }
      )}
    >
      {isVisibleTag && (
        <Tag
          text={isCorrect ? 'Correct' : 'Incorrect'}
          className={clsx(
            s.Answer__tag,
            { [s.Answer__tag_green]: isCorrect },
            { [s.Answer__tag_red]: !isCorrect }
          )}
        />
      )}

      <ReactMarkdown className={s.Answer__text}>{text}</ReactMarkdown>
    </li>
  )
}

export default Answer
