import { onError } from '@apollo/client/link/error'
import { EnumToastVariants } from '@insquad/tools'

import { openToast } from 'common/components/Toast/Toast'

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    ;(graphQLErrors as any[]).forEach(({ errorType, message, reason }) => {
      openToast({
        text: `ErrorType: ${errorType}, Message: ${message}, Reason: ${reason}`,
        variant: EnumToastVariants.ERROR,
      })
    })
  }

  if (networkError) {
    openToast({
      text: 'Internet connection not stable...',
      variant: EnumToastVariants.ERROR,
    })
  }
})
