import React from 'react'

import { useIsDesktop, useIsTablet } from '@insquad/tools'

import { Logo } from 'common/components/Logo/Logo'

import s from './NotFoundHeader.module.scss'

export interface INotFoundHeaderProps {}

export const NotFoundHeader: React.FC<INotFoundHeaderProps> = () => {
  const isTablet = useIsTablet()
  const isDesktop = useIsDesktop()

  return (
    <header className={s.NotFoundHeader}>
      <Logo isSmall={!isTablet && !isDesktop} isLink />
    </header>
  )
}
