import React from 'react'

import QuestionList from 'common/components/QuestionList/QuestionList'
import { Task } from 'graphql/types'

import s from './QuestionViewPage.module.scss'

interface IQuestionViewPageProps {
  publicName: string
  Task: Task
}

export const QuestionViewPage: React.FC<IQuestionViewPageProps> = ({
  publicName,
  Task,
}) => {
  return (
    <div className={s.QuestionViewPage}>
      <QuestionList
        questions={Task?.Quiz?.Questions || []}
        titleModal={`${publicName} Quiz`}
      />
    </div>
  )
}
