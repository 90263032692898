import { gql } from '@apollo/client'

export const FRAGMENT_CODING_FULL = gql`
  fragment FragmentCodingFull on Coding {
    id
    name
    publicName
    publicNameRu
    text
    textRu
    complexity
    initialCode
    currentCode
    finalCode
    environment
  }
`
