import React from 'react'
import { toast, ToastOptions } from 'react-toastify'

import {
  EnumToastVariants,
  Toast as ToastCmp,
  IToastProps,
} from '@insquad/tools'

export const Toast = Object.assign(ToastCmp, {
  Variant: EnumToastVariants,
})

export const openToast = (toastProps: IToastProps, options?: ToastOptions) =>
  toast(<Toast {...toastProps} />, options)
