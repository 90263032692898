import { MutationHookOptions, useMutation } from '@apollo/client'

import {
  IAnswerQuestionResponse,
  IAnswerQuestionVars,
  MUTATION_ANSWER_QUESTION,
} from '../gql/answerQuestion'

export const useAnswerQuestionMutation = (
  options?: MutationHookOptions<IAnswerQuestionResponse, IAnswerQuestionVars>
) => {
  return useMutation<IAnswerQuestionResponse, IAnswerQuestionVars>(
    MUTATION_ANSWER_QUESTION,
    options
  )
}
