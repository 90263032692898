import React from 'react'
import { useRoutes } from 'react-router-dom'

import { VIEW_ROUTES } from './routes'

export const ViewRoutes: React.FC = () => {
  const viewRoutes = useRoutes(VIEW_ROUTES)

  return viewRoutes
}
