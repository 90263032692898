import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import {
  Button,
  EnumTypographyVariants,
  Typography,
  useToggle,
} from '@insquad/tools'

import s from './TaskFinished.module.scss'

export const TaskFinished = () => {
  const [counter, setCounter] = useState(0)
  const isVisible = useToggle()
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const handleReload = () => {
    window.parent.postMessage({ type: 'reload-page' }, '*')
  }

  useEffect(() => {
    const timer = setInterval(() => setCounter(counter + 1), 1000)
    if (counter === 5) {
      isVisible.set()
      clearInterval(timer)
    }
    return () => clearInterval(timer)
  }, [counter])

  return (
    <div className={s.TaskFinished}>
      <Typography
        variant={EnumTypographyVariants.H3}
        text={isRuLocale ? 'Задание закончено' : `Task finished`}
      />
      {!isVisible.value && (
        <Typography
          variant={EnumTypographyVariants.P2}
          text={isRuLocale ? 'Подождите секунду…' : `Wait a second…`}
        />
      )}
      {isVisible.value && <Button onClick={handleReload}>Reload</Button>}
    </div>
  )
}
