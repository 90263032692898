import { gql } from '@apollo/client'

import { FRAGMENT_CODING_FULL } from './codingFragments'
import { FRAGMENT_EVENT_FULL } from './eventFragment'
import { FRAGMENT_QUIZ_FULL, FRAGMENT_QUIZ_PASS } from './quizFragment'

export const FRAGMENT_TASK_FULL = gql`
  fragment FragmentTaskFull on Task {
    id
    type
    status
    score
    maxScore
    duration
    netTime
    startedAt
    finishedAt
    Tags {
      name
      publicName
      publicNameRu
    }
    Quiz {
      ...FragmentQuizFull
    }
    Coding {
      ...FragmentCodingFull
    }
    Events {
      ...FragmentEventFull
    }
  }

  ${FRAGMENT_QUIZ_FULL}
  ${FRAGMENT_CODING_FULL}
  ${FRAGMENT_EVENT_FULL}
`

export const FRAGMENT_TASK_PASS = gql`
  fragment FragmentTaskPass on Task {
    id
    type
    status
    score
    maxScore
    duration
    netTime
    isMultiEnvironment
    startedAt
    finishedAt
    timeLimit
    Tags {
      name
      publicName
      publicNameRu
    }
    Quiz {
      ...FragmentQuizPass
    }
    Coding {
      ...FragmentCodingFull
    }
    Events {
      ...FragmentEventFull
    }
  }

  ${FRAGMENT_QUIZ_PASS}
  ${FRAGMENT_CODING_FULL}
  ${FRAGMENT_EVENT_FULL}
`
