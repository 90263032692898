import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import {
  EnumBreakpoints,
  IToggler,
  useMediaQuery,
  useToggle,
  useWindowSize,
} from '@insquad/tools'
import Editor from '@monaco-editor/react'

import { TimeCounter } from 'common/components/TimeCounter/TimeCounter'
import { CodingTest, Task } from 'graphql/types'

import { CodingContentAside } from './CodingContentAside/CodingContentAside'
import { ReactComponent as SliderIcon } from './sliderIcon.svg'

import s from './CodingContent.module.scss'

export const CodingContent: React.FC<{
  currentTask: number
  amountTasks: number
  fontSize: number
  timer: number
  Task: Task
  code: string
  CodingTest?: CodingTest
  environment: string
  isLoadingTestRunner: boolean
  isTaskVisible: IToggler
  initialWidth: number

  handleEditorChange: (value: string | undefined) => void
}> = ({
  currentTask,
  amountTasks,
  fontSize,
  timer,
  Task,
  code,
  CodingTest,
  environment,
  initialWidth,
  handleEditorChange,
  isLoadingTestRunner,
  isTaskVisible,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const [asideWidth, setAsideWidth] = useState(initialWidth)
  const isActiveSlider = useToggle()
  const { width } = useWindowSize()
  const isDesktop = useMediaQuery(EnumBreakpoints.BREAKPOINT_LG, 'min')

  const handleMouseMove = (event: MouseEvent) => {
    if (event.clientX > 240) {
      setAsideWidth(event.clientX)
    } else {
      handleMouseUp()
    }
  }

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
    isActiveSlider.unset()
  }

  const handleMouseDown = () => {
    isActiveSlider.set()
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  return (
    <div className={s.CodingContent}>
      <CodingContentAside
        Task={Task}
        CodingTest={CodingTest}
        isLoadingTestRunner={isLoadingTestRunner}
        isTaskVisible={isTaskVisible}
        asideWidth={isDesktop ? `${asideWidth}px` : '100%'}
      />
      <div className={s.CodingContent__code}>
        <div className={s.CodingContent__codeTitle}>
          <TimeCounter
            timer={timer}
            title={
              amountTasks !== 1
                ? `${
                    isRuLocale ? 'Задача' : 'Task'
                  } ${currentTask}/${amountTasks}`
                : `${isRuLocale ? 'Задача' : 'Task'}`
            }
            isBlack
          />
        </div>
        <Editor
          width={isDesktop ? `${width - asideWidth}px` : '100%'}
          height={'93%'}
          language={environment}
          value={code}
          onChange={handleEditorChange}
          options={{
            scrollbar: { vertical: 'hidden' },
            fontSize: fontSize,
            scrollBeyondLastLine: false,
          }}
        />
        {isDesktop && (
          <SliderIcon
            className={clsx(s.CodingContent__slider, {
              [s.CodingContent__slider_active]: isActiveSlider.value,
            })}
            onMouseDown={handleMouseDown}
          />
        )}
      </div>
    </div>
  )
}
