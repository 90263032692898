import { gql } from '@apollo/client'

import { FRAGMENT_SESSION_FULL } from 'graphql/fragments/sessionFragment'
import { Session } from 'graphql/types'

export interface IGetDetailedReportResponse {
  result: Session
}

export interface IQueryGetDetailedReportVars {
  externalId: string
}

export const QUERY_GET_DETAILED_REPORT = gql`
  query getDetailedReport($externalId: String!) {
    result: getDetailedReport(externalId: $externalId) {
      ...FragmentSessionFull
    }
  }

  ${FRAGMENT_SESSION_FULL}
`
