import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import Tooltip from 'common/components/Tooltip/Tooltip'

import s from './CodeLaunchPoint.module.scss'

export const CodeLaunchPoint: React.FC<{
  isPassed: boolean
  isActivePoint: boolean
  order: number
  index: number
  textTooltip: string
  position: number

  handleClickPoint: (
    e: React.MouseEvent<HTMLDivElement>,
    i: number,
    index: number
  ) => void
  handleEnterPoint: () => void
  handleLeavePoint: () => void
}> = ({
  isPassed,
  order,
  textTooltip,
  position,
  index,
  isActivePoint,
  handleClickPoint,
  handleEnterPoint,
  handleLeavePoint,
}) => {
  return (
    <Tooltip
      text={
        <Typography
          variant={EnumTypographyVariants.P3}
          className={s.CodeLaunchPoint__tooltip}
          as={'div'}
        >
          Code Launch #{order + 1}
          <Typography
            variant={EnumTypographyVariants.P3}
            className={clsx({
              [s.CodeLaunchPoint__tooltip_red]: !isPassed,
              [s.CodeLaunchPoint__tooltip_green]: isPassed,
            })}
            text={textTooltip}
          />
        </Typography>
      }
      position={position}
    >
      <div
        className={clsx(s.CodeLaunchPoint__point, {
          [s.CodeLaunchPoint__point_active]: isActivePoint,
        })}
        style={{
          left: `${position}px`,
        }}
        onMouseEnter={handleEnterPoint}
        onMouseLeave={handleLeavePoint}
        onClick={(e) => handleClickPoint(e, order, index)}
      ></div>
    </Tooltip>
  )
}
