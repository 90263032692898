function env<T = string>(variable: string | undefined, defaultValue: T) {
  if (typeof variable === 'undefined') {
    return defaultValue
  }
  return variable
}

function requireEnv(variable: string | undefined, name: string) {
  if (typeof variable === 'undefined') {
    throw new Error(`Variable ${name} is required`)
  }
  return variable
}

export const REACT_APP_BACKEND_API = requireEnv(
  process.env.REACT_APP_BACKEND_API,
  'REACT_APP_BACKEND_API'
)

export const REACT_APP_SENTRY_DSN = env(process.env.REACT_APP_SENTRY_DSN, '')
