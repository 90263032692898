import React from 'react'

import clsx from 'clsx'

import { EnumTypographyVariants, Typography } from '@insquad/tools'

import { ReactComponent as ClockIcon } from 'assets/icons/simple/clockIcon.svg'

import s from './TimeCounter.module.scss'

const getTime = (seconds: number) => {
  if (seconds <= 0) {
    return '00:00'
  }
  const h = Math.floor(seconds / 3600)
  const m = String(Math.floor((seconds % 3600) / 60) + h * 60).padStart(2, '0')
  const s = String(Math.floor((seconds % 3600) % 60)).padStart(2, '0')

  return `${m}:${s}`
}

export const TimeCounter: React.FC<{
  timer: number
  title: string
  isBlack?: boolean
}> = ({ timer, title, isBlack }) => {
  return (
    <div
      className={clsx(s.TimeCounter__title, {
        [s.TimeCounter__title_black]: isBlack,
      })}
    >
      <Typography variant={EnumTypographyVariants.H2} text={title} />
      <div className={s.TimeCounter__timeBox}>
        <ClockIcon />
        <Typography
          variant={EnumTypographyVariants.H2}
          text={getTime(timer)}
          className={s.TimeCounter__time}
        />
      </div>
    </div>
  )
}
