import React from 'react'
import { usePopperTooltip } from 'react-popper-tooltip'
import { useSearchParams } from 'react-router-dom'

import clsx from 'clsx'

import {
  EnumBreakpoints,
  useMediaQuery,
  EnumTypographyVariants,
  Typography,
} from '@insquad/tools'

import { ReactComponent as HelpIcon } from 'assets/icons/simple/helpIcon.svg'
import { Maybe, Tag } from 'graphql/types'

import { ProgressCircle } from '../ProgressCircle/ProgressCircle'

import s from './TitleTask.module.scss'

const tagStyle = [
  s.TitleTask__tag_blue,
  s.TitleTask__tag_green,
  s.TitleTask__tag_violet,
]

const getTime = (time: number, isRuLocale: boolean): string => {
  const h = Math.floor(time / 3600)
  const m = Math.floor((time % 3600) / 60)
  const s = Math.floor((time % 3600) % 60)

  return `${h ? `${h}${!isRuLocale ? 'h' : 'ч'} ` : ''}${
    m ? `${m}${!isRuLocale ? 'm' : 'м'} ` : ''
  } ${s}${!isRuLocale ? 's' : 'с'}`
}

interface ITitleTaskProps {
  title: string
  isSmall?: boolean
  isSmallTextCircle?: boolean
  isBorderHidden?: boolean
  isAnimatedCircle?: boolean
  tags?: Maybe<Tag[]> | undefined
  netTime?: number
  score: number
  maxScore: number
  duration: number
}

const TitleTask: React.FC<ITitleTaskProps> = ({
  title,
  isSmall,
  isSmallTextCircle,
  isBorderHidden,
  isAnimatedCircle,
  tags,
  netTime,
  score,
  maxScore,
  duration,
}) => {
  const [searchParams] = useSearchParams()
  const isRuLocale = searchParams.get('locale') === 'RU'
  const isMobile = useMediaQuery(EnumBreakpoints.BREAKPOINT_SM, 'max')

  const sizeCircleDesktop = !isSmall ? 110 : 62
  const sizeCircleMobile = !isSmall ? 90 : 62

  const { getTooltipProps, setTriggerRef, setTooltipRef, visible } =
    usePopperTooltip({
      placement: 'top-start',
      offset: [10, 10],
    })

  return (
    <div
      className={clsx(s.TitleTask, {
        [s.TitleTask_borderHidden]: isBorderHidden,
      })}
    >
      {isMobile && (
        <Typography
          variant={
            !isSmall ? EnumTypographyVariants.H1 : EnumTypographyVariants.H2
          }
          text={title}
          className={s.TitleTask__titleMobile}
        />
      )}

      <div className={s.TitleTask__titleWrapper}>
        <div className={s.TitleTask__title}>
          <ProgressCircle
            classes={{
              svgClassName: clsx(s.TitleTask__circle, {
                [s.TitleTask__circle_small]: isSmallTextCircle,
              }),
            }}
            percentage={Number(((score / maxScore) * 100).toFixed())}
            size={!isMobile ? sizeCircleDesktop : sizeCircleMobile}
            strokeWidth={6}
            color={'blue'}
            text={'yes'}
            isAnimated={isAnimatedCircle}
            score={score}
            maxScore={maxScore}
            isVisible
            isOpen
          />

          {!isMobile && (
            <Typography
              variant={
                !isSmall ? EnumTypographyVariants.H1 : EnumTypographyVariants.H2
              }
              text={title}
            />
          )}
        </div>

        <Typography
          variant={
            !isSmall ? EnumTypographyVariants.H1 : EnumTypographyVariants.H2
          }
          className={s.TitleTask__time}
        >
          {getTime(duration, isRuLocale)}
          {!!netTime && (
            <Typography
              variant={EnumTypographyVariants.P2}
              className={s.TitleTask__netTime}
              as="div"
              ref={setTriggerRef}
            >
              {!isRuLocale ? 'Net time' : 'Чистое время'}
              <HelpIcon className={s.TitleTask__netTimeIcon} />
              {getTime(netTime, isRuLocale)}
              {visible && (
                <Typography
                  {...getTooltipProps({ ref: setTooltipRef })}
                  variant={EnumTypographyVariants.P3}
                  className={s.TitleTask__tooltip}
                  text={
                    !isRuLocale
                      ? 'The time a candidate spent working on the coding challenge keeping the tab active'
                      : 'Время, затраченное кандидатом на выполнение задания с сохранением активной вкладки'
                  }
                  as="div"
                />
              )}
            </Typography>
          )}
        </Typography>
      </div>
      {!!tags?.length && (
        <Typography
          variant={EnumTypographyVariants.P2}
          as="div"
          className={s.TitleTask__wrapperTags}
        >
          {!isRuLocale ? 'Tested skills' : 'Проверенные навыки'}
          <div className={s.TitleTask__tags}>
            {tags.map((tag, i) => (
              <div key={i} className={clsx(s.TitleTask__tag, tagStyle[i % 3])}>
                <Typography
                  variant={
                    !isMobile
                      ? EnumTypographyVariants.P3
                      : EnumTypographyVariants.CAPTION
                  }
                  text={!isRuLocale ? tag.publicName : tag.publicNameRu ?? ''}
                />
              </div>
            ))}
          </div>
        </Typography>
      )}
    </div>
  )
}

export default TitleTask
