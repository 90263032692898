import React from 'react'

import { Button, Typography } from '@insquad/tools'

import { NotFoundHeader } from './NotFoundHeader/NotFoundHeader'
import { ReactComponent as NotFoundIcon } from './notFoundIcon.svg'

import s from './NotFoundLayout.module.scss'

export interface INotFoundLayoutProps {
  btnText?: string
  onClickBtn?: () => void
}

export const NotFoundLayout: React.FC<INotFoundLayoutProps> = ({
  btnText = 'Go Home',
  onClickBtn,
}) => {
  return (
    <main className={s.NotFoundLayout}>
      <NotFoundHeader />

      <section className={s.NotFoundLayout__content}>
        <div className={s.NotFoundLayout__warning}>
          <NotFoundIcon />

          <Typography
            className={s.NotFoundLayout__title}
            text="Something went wrong..."
          />

          {onClickBtn && (
            <Button className={s.NotFoundLayout__btn} onClick={onClickBtn}>
              {btnText}
            </Button>
          )}
        </div>
      </section>
    </main>
  )
}
