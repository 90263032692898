import { MutationHookOptions, useMutation } from '@apollo/client'

import {
  IChangeEnvironmentResponse,
  IChangeEnvironmentVars,
  MUTATION_ANSWER_QUESTION,
} from '../gql/changeEnvironment'

export const useChangeEnvironmentMutation = (
  options?: MutationHookOptions<
    IChangeEnvironmentResponse,
    IChangeEnvironmentVars
  >
) => {
  return useMutation<IChangeEnvironmentResponse, IChangeEnvironmentVars>(
    MUTATION_ANSWER_QUESTION,
    options
  )
}
